import React, { Suspense, lazy } from "react";
import { Button, Input, Label } from "reactstrap";
import {
  checkPermission,
  formatAddressInSingleText,
  formatPhoneNumber,
  getDropdownColor,
} from "../../../helper-methods";
const SignerPopover = lazy(() => import("./common/popover"));

const ClientAgentCardDataFormat = ({ row, loading, _clientStatusUpdate }) => {
  return (
    <ul className="cardInfo">
      <li>
        <div className="dataWrap">
          <Label>Email</Label>
          <div>
            <span
              style={{
                marginRight: 5,
                fontWeight: 600,
              }}
            >
              {row.email ? row.email : "N/A"}
            </span>
          </div>
        </div>
        <div className="dataWrap">
          <Label>Phone</Label>
          <div>
            <span
              style={{
                marginRight: 5,
                fontWeight: 600,
              }}
            >
              {row.phone ? formatPhoneNumber(row.phone) : "N/A"}
            </span>
          </div>
        </div>
      </li>
      <li>
        <div className="dataWrap">
          <Label>Location</Label>
          <div className="d-flex justify-content-between">
            <div style={{ fontWeight: 600, flex: 1, marginRight: 10 }}>
              {formatAddressInSingleText(row.closingAddress) || "N/A"}
            </div>
            {formatAddressInSingleText(row.closingAddress) ? (
              <div className="d-flex">
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Google_Maps_Logo_2020.svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-3"
                  />
                </Button>
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                        .default
                    }
                    alt="map icon"
                  />
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        {/* <div className="dataWrap">
          <Label>Client Id</Label>
          <div>
            <span
              style={{
                marginRight: 5,
                fontWeight: 600,
              }}
            >
              {row?.clientId ? row?.clientId : "N/A"}
            </span>
          </div>
        </div> */}
        <div className="dataWrap">
          <Label>Tags</Label>
          <div>
            {row?.noOftags || row?.noOftags === 0 ? (
              <>
                <Button
                  color="link"
                  id={`agent_client_tags${row?._id}`}
                  type="button"
                >
                  {row?.noOftags}
                </Button>
                {row?.tags?.length > 0 ? (
                  <Suspense fallback={<></>}>
                    <SignerPopover
                      data={row?.tags}
                      targetId={row?._id}
                      displayType="tags"
                    />
                  </Suspense>
                ) : null}
              </>
            ) : (
              "N/A"
            )}
          </div>
        </div>
      </li>

      <li>
        <div className="dataWrap">
          <Label>Point Of Contact</Label>
          <div>
            <span
              style={{
                marginRight: 5,
                fontWeight: 600,
              }}
            >
              {row?.pointOfContact ? row?.pointOfContact : "N/A"}
            </span>
          </div>
        </div>
      </li>
      <li>
        <div className="dataWrap d-flex align-items-center justify-content-between">
          <Label className="mb-0">Status</Label>
          <div className="customSelectWrap">
            <Input
              type="select"
              className={`status ${getDropdownColor(
                row.isActive ? "active" : "inactive"
              )}`}
              name="status"
              disabled={
                loading.statusChangeLoading ||
                !checkPermission("clients", "canUpdate")
              }
              value={row.isActive ? "active" : "inactive"}
              onChange={(event) => _clientStatusUpdate(row, event.target.value)}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
            <div className="downArrow">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default ClientAgentCardDataFormat;
