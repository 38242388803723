import React from "react";
import { FormGroup, Input, Modal, ModalBody, ModalHeader } from "reactstrap";

const CommentModal = ({ isOpen, data, toggle }) => {
  const _closeModal = () => {
    toggle();
  };
  return (
    <Modal isOpen={isOpen} toggle={_closeModal} centered scrollbar>
      <ModalHeader toggle={_closeModal}>Comment</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            type="textarea"
            rows="5"
            placeholder=" "
            value={data}
            readOnly

            // onChange={(e) => _onChangeNotes(e.target.value)}
          />
          {/* <Label> {"comment"}</Label> */}
        </FormGroup>
      </ModalBody>
    </Modal>
  );
};

export default CommentModal;
