import React from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from "reactstrap";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { countryCodes } from "../../../config/country-codes";
import {
  signupAgentViaEmail,
  signupSigningCompanyViaEmail,
  signupSigningCompanyViaInvitation,
  signupAgentViaInvitation,
  signupClientViaEmail,
  signupClientViaInvitation,
} from "../../../http/http-calls";
import ClosingAddress from "../../private/components/common/closingAddress";
import Swal from "sweetalert2";
import { RegexConfig } from "../../../config/RegexConfig";

import {
  capitalize,
  deepClone,
  errorHandler,
  getPhoneNumberFromBrackets,
  showToast,
} from "../../../helper-methods";
import jwt_decode from "jwt-decode";

const DEFAULT_PHONE_COUNTRY = "US";
const USER_TYPE_ATTORNEY = "Attorney";
const USER_TYPE_NOTARY = "Notary";
const USER_TYPE_SIGNING_COMPANY_LAWFIRMS = "SigningCompanyLawfirms";
const USER_TYPE_SIGNING_COMPANY_TITLE_ESCROW = "SigningCompanyTitle&Escrow";
const USER_TYPE_SIGNING_COMPANY_SIGNING_SERVICE =
  "SigningCompanySigningService";
const USER_TYPE_CLIENT_LAW = "ClientLaw";
const USER_TYPE_CLIENT_TITLE_ESCROW = "ClientTitle&Escrow";
const USER_TYPE_CLIENT_LENDER = "ClientLender";

const FIELD_TYPES = {
  PHONE: "phone",
  WEBSITE_URL: "websiteURL",
  ADDRESS: "address",
  PASSWORD: "password",
  TERMS_AND_CONDITIONS: "isTermsAndConditionsAccepted",
};

const createFormField = (value = "", isRequired = false) => ({
  value,
  error: null,
  isDirty: false,
  isRequired,
});

// Define form fields for each user type
const formFieldsConfig = {
  Notary: ["name", "phone", "email", "password", "address"],
  Attorney: ["name", "phone", "email", "password", "address"],
  SigningCompany: [
    "name",
    "phone",
    "email",
    "password",
    "companyName",
    "websiteURL",
    "jobTitle",
    "zip",
  ],
  Client: ["name", "phone", "email", "password", "companyName", "zip"],
};

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.addressRef = React.createRef();
  }
  state = {
    formFields: {
      userType: createFormField("", true),
      name: createFormField("", true),
      email: createFormField("", true),
      phoneCountry: createFormField(DEFAULT_PHONE_COUNTRY, true),
      phone: createFormField("", true),
      password: createFormField("", true),
      companyName: createFormField("", true),
      websiteURL: createFormField(),
      jobTitle: createFormField(),
      zip: createFormField(),
      licenseNumber: createFormField(),
      isTermsAndConditionsAccepted: createFormField(),
    },
    isShowPassword: false,
    showMenu: false,
    termsAndConditionModal: {
      isOpen: false,
      data: null,
    },
    companyDetail: {},
    decodedData: null,
    viewFor: "",
  };

  componentDidMount() {
    const { formFields } = this.state;
    formFields["userType"].value = this.props.userType;
    const token = this.props.token;

    if (token) {
      // if user comes from invitation link
      const decoded = jwt_decode(token);

      Object.entries(decoded).forEach(([decodedKey, decodedValue]) => {
        Object.entries(formFields).forEach(([item, each]) => {
          if (decodedKey === "invitedUserType") {
            if (decodedValue === "Agent") {
              // formFields["userType"].value = decoded?.workType;

              if (decoded?.workType) {
                formFields["userType"].value = decoded?.workType;
              } else {
                formFields["userType"].value = this.props.userType;
              }

              if (decoded?.address !== undefined) {
                if (!formFields["searchAddress"]) {
                  formFields["searchAddress"] = { value: "" };
                  formFields["line1"] = { value: "" };
                  formFields["city"] = { value: "" };
                  formFields["state"] = { value: "" };
                  formFields["country"] = { value: "" };
                  formFields["zip"] = { value: "" };
                  formFields["lat"] = { value: "" };
                  formFields["lng"] = { value: "" };
                }

                formFields["searchAddress"].value = decoded?.address || "";
                // formatAddressInSingleText(decoded?.address) || "";
                formFields["line1"].value = decoded?.address?.street || "";
                formFields["city"].value = decoded?.address?.city || "";
                formFields["state"].value = decoded?.address?.state || "";
                formFields["country"].value = decoded?.address?.country || "";
                formFields["zip"].value = decoded?.address?.zip || "";
                formFields["lat"].value = decoded?.address?.lat || "";
                formFields["lng"].value = decoded?.address?.lng || "";
              }
            } else {
              formFields[`userType`].value =
                decodedValue === "Client"
                  ? "ClientTitle&Escrow"
                  : "SigningCompanySigningService";
            }
          }
          if (item === decodedKey) {
            formFields[decodedKey].value = decodedValue;
            if (item === "name") {
              formFields[decodedKey].value = `${
                decodedValue?.first ? decodedValue?.first : ""
              } ${decodedValue?.last ? decodedValue?.last : ""}`;
            }
            if (item === "phone") {
              formFields[decodedKey].value =
                getPhoneNumberFromBrackets(decodedValue);
            }
          }
        });
      });

      this.setState({ formFields });
    }
    if (
      this.props.userType === "Notary" ||
      this.props.userType === "Attorney"
    ) {
      this.setState({ viewFor: "Notary" });
    } else if (
      this.props.userType === "ClientTitle" ||
      this.props.userType === "ClientLender" ||
      this.props.userType === "ClientTitle&Escrow"
    ) {
      this.setState({ viewFor: "Client" });
    } else if (
      this.props.userType === "SigningCompanyTitle&Escrow" ||
      this.props.userType === "SigningCompanySigningService" ||
      this.props.userType === "SigningCompanyLawFirms"
    ) {
      this.setState({ viewFor: "SigningCompany" });
    }
  }

  _resetFormField = () => {
    this.setState({
      formFields: {
        userType: createFormField("", true),
        name: createFormField("", true),
        email: createFormField("", true),
        phoneCountry: createFormField(DEFAULT_PHONE_COUNTRY, true),
        phone: createFormField("", true),
        password: createFormField("", true),
        companyName: createFormField("", true),
        websiteURL: createFormField(),
        jobTitle: createFormField(),
        zip: createFormField(),
        licenseNumber: createFormField(),
        isTermsAndConditionsAccepted: createFormField(),
      },
      isShowPassword: false,
      showMenu: false,
    });
  };

  _showPasswordToggle = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  // Define event handlers as methods
  _onChangeFormField = (type, value) => {
    const { formFields } = this.state;
    formFields[type].value = value;
    formFields[type].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isRequired && formFields[e].isDirty) {
          switch (e) {
            case "name": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (formFields[e]?.value?.trim()?.length <= 40) {
                  if (
                    /[a-zA-Z]/g.test(String(formFields[e].value).toLowerCase())
                  ) {
                    formFields[e].isDirty = false;
                    formFields[e].error = null;
                  } else {
                    formFields[e].isDirty = true;
                    formFields[e].error =
                      "*Name containing only number and special character are not allowed";
                    isFormValid = false;
                  }
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Only upto 40 characters are allowed";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "email": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Invalid Phone Number";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "password": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.password.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error =
                    "*Minimum eight characters, at least one letter, one number and one special character";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "companyName": {
              if (
                formFields.userType.value === "ClientLaw" ||
                formFields.userType.value === "ClientTitle&Escrow" ||
                formFields.userType.value === "ClientLender" ||
                formFields.userType.value === "SigningCompanyLawfirms" ||
                formFields.userType.value === "SigningCompanyTitle&Escrow" ||
                formFields.userType.value === "SigningCompanySigningService"
              ) {
                if (formFields[e].value && formFields[e].value.trim().length) {
                  if (
                    /[a-zA-Z]/g.test(String(formFields[e].value).toLowerCase())
                  ) {
                    formFields[e].isDirty = false;
                    formFields[e].error = null;
                  } else {
                    formFields[e].isDirty = true;
                    formFields[e].error = "At least one Alphabet is Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = false;
                formFields[e].error = null;
              }
              break;
            }

            case "isTermsAndConditionsAccepted": {
              if (formFields[e].value === true) {
                formFields[e].isDirty = false;
                formFields[e].error = null;
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  // this fucntion create payload for signUp function only for Attorney and Notary
  prepareDataForAttorneyOrNotary = (formFields, addressResponse, token) => {
    const splitName = formFields.name.value.trim().split(" ");
    const countryCode = countryCodes.find(
      (obj) => obj.code === formFields.phoneCountry.value
    );

    let data = {
      email: formFields.email.value,
      phoneCountry: formFields.phoneCountry.value,
      name: {
        first: splitName[0],
        last: splitName.slice(1).join(" ") ? splitName.slice(1).join(" ") : "",
      },
      password: formFields.password.value,
      workType: formFields.userType.value,
      licenseNumber: formFields.licenseNumber.value,
      invitationToken: token,
      isTermsAndConditionsAccepted:
        formFields.isTermsAndConditionsAccepted.value,
      address: {
        street: addressResponse?.address?.addressLine1?.value,
        city: addressResponse?.address?.city?.value,
        state: addressResponse?.address?.state?.value,
        zip: addressResponse?.address?.zip?.value,
        country: addressResponse?.address?.country?.value,
        lat: addressResponse?.address?.lat?.value,
        lng: addressResponse?.address?.lng?.value,
      },
    };

    if (
      addressResponse?.address?.zip?.value &&
      addressResponse?.address?.zip?.value.trim().length
    ) {
      data["zip"] = addressResponse?.address?.zip?.value;
    }

    console.log("countryCode >>", countryCode);

    if (countryCode && formFields.phone.value) {
      console.log("Set data");
      data["phone"] = `(${countryCode.dial_code})${formFields.phone.value}`;
    }

    return data;
  };

  // this not for Attorney and Notary
  prepareData = (formFields, token, isSigningCompany) => {
    const splitName = formFields.name.value.trim().split(" ");
    const countryCode = countryCodes.find(
      (obj) => obj.code === formFields.phoneCountry.value
    );

    let data = {
      email: formFields.email.value,
      phoneCountry: formFields.phoneCountry.value,
      name: {
        first: splitName[0],
        last: splitName.slice(1).join(" ") ? splitName.slice(1).join(" ") : "",
      },
      password: formFields.password.value,
      companyName: formFields.companyName.value,
      isTermsAndConditionsAccepted:
        formFields.isTermsAndConditionsAccepted.value,
      invitationToken: token,
    };

    if (isSigningCompany) {
      data.isTitleCompany =
        formFields.userType.value === "SigningCompanyTitle&Escrow";
      data.websiteURL = formFields.websiteURL.value;
      data.jobTitle = formFields.jobTitle.value;
    } else {
      data.businessCountry = formFields.phoneCountry.value;
      data.workType = formFields.userType.value;
    }

    if (formFields.zip.value && formFields.zip.value.trim().length) {
      data.zip = formFields.zip.value;
    }

    if (countryCode && formFields.phone.value) {
      data["phone"] = `(${countryCode.dial_code})${formFields.phone.value}`;
    }

    return data;
  };

  signupUser = async (data, signupFunction) => {
    try {
      await signupFunction(data);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          popup: "alert-swal-modal",
        },
      });

      swalWithBootstrapButtons.fire({
        imageUrl: require("../../../assets/img/rightThump.svg").default,
        imageAlt: "A tall image",
        title: "Request Sent Successfully!",
        text: `Please check your email to verify your account submission.Thank you!`,
        // icon: 'success'
        confirmButtonText: "OKAY",
      });
      this.props.hideLoader();
      this.props.history.replace(`/login`);
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _userSignup = async (e) => {
    console.log("call");
    if (e) e.preventDefault();

    await this._markAllFieldDirty();
    // get data form closing address component with ref
    const addressResponse = await this.addressRef?.current?._handleSubmit();
    const isFormValid = await this._validateForm();

    if (!isFormValid) {
      showToast("Please fill all the required fields");
      return;
    }

    this.props.showLoader("Loading...");

    const { formFields } = deepClone(this.state);

    let data;
    let signupFunction;
    let token = this.props.history.location.pathname.split("/")[2];

    if (
      formFields.userType.value === USER_TYPE_ATTORNEY ||
      formFields.userType.value === USER_TYPE_NOTARY
    ) {
      data = this.prepareDataForAttorneyOrNotary(
        formFields,
        addressResponse,
        token
      );
      // set function as a variable and send it based on the user token have or not
      // if token have => Invite user
      signupFunction = token ? signupAgentViaInvitation : signupAgentViaEmail;
    } else if (
      formFields.userType.value === USER_TYPE_SIGNING_COMPANY_LAWFIRMS ||
      formFields.userType.value === USER_TYPE_SIGNING_COMPANY_TITLE_ESCROW ||
      formFields.userType.value === USER_TYPE_SIGNING_COMPANY_SIGNING_SERVICE
    ) {
      data = this.prepareData(formFields, token, true);
      // set function as a variable and send it based on the user token have or not
      // if token have => Invite user
      signupFunction = token
        ? signupSigningCompanyViaInvitation
        : signupSigningCompanyViaEmail;
    } else if (
      formFields.userType.value === USER_TYPE_CLIENT_LAW ||
      formFields.userType.value === USER_TYPE_CLIENT_TITLE_ESCROW ||
      formFields.userType.value === USER_TYPE_CLIENT_LENDER
    ) {
      data = this.prepareData(formFields, token, false);
      // set function as a variable and send it based on the user token have or not
      // if token have => Invite user
      signupFunction = token ? signupClientViaInvitation : signupClientViaEmail;
    } else {
      showToast("User type is invalid", "error");
      return;
    }

    this.signupUser(data, signupFunction);
  };

  // this function create single form field based on filed
  // like: Name, Email, Password etc
  renderInputField = (field) => {
    if (field === FIELD_TYPES.ADDRESS) {
      return (
        <ClosingAddress
          ref={this.addressRef}
          fieldName="address"
          placeholder=" "
          label="Search Address"
          // isManualAddressRequired={false}
          data={this?.state?.formFields?.searchAddress?.value}
          isRequired={true}
          isColNeed={false}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          // googleAddress={formatAddressInSingleText(
          //   this?.state?.formFields?.searchAddress?.value
          // )}
        />
      );
    }

    const isInputGroup =
      field === FIELD_TYPES.PHONE || field === FIELD_TYPES.WEBSITE_URL;
    const isWebsiteURL = field === FIELD_TYPES.WEBSITE_URL;
    const isPassword = field === FIELD_TYPES.PASSWORD;
    const isPhone = field === FIELD_TYPES.PHONE;

    return (
      <FormGroup
        className={
          isInputGroup ? "floatingLabel withInputGroup" : "floatingLabel"
        }
        key={field}
      >
        <InputGroup>
          {isWebsiteURL || isPhone ? (
            <InputGroupAddon addonType="prepend">
              {isPhone ? (
                <InputGroupText>+1</InputGroupText>
              ) : (
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/globeIcon.svg").default}
                    alt=""
                  />
                </InputGroupText>
              )}
            </InputGroupAddon>
          ) : null}
          <Input
            type={
              isPassword && !this.state.isShowPassword ? "password" : "text"
            }
            name={field}
            placeholder=" "
            value={this.state.formFields[field]?.value}
            onChange={(e) => this._onChangeFormField(field, e.target.value)}
            autoComplete="new-password"
          />
          <Label>{capitalize(field)}</Label>
          {isPassword && (
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <img
                  src={require(`../../../assets/img/${
                    this.state.isShowPassword
                      ? "eyeIcon.svg"
                      : "eyeIconSlash.svg"
                  }`)}
                  onClick={this._showPasswordToggle}
                  alt="eye icon"
                />
              </InputGroupText>
            </InputGroupAddon>
          )}
        </InputGroup>
        {this.state.formFields[field]?.isDirty &&
          this.state.formFields[field]?.error && (
            <div className="validation-error">
              {this.state.formFields[field]?.error}
            </div>
          )}
      </FormGroup>
    );
  };

  render() {
    const { viewFor } = this.state;
    const formFields = formFieldsConfig[viewFor];

    // console.log("userType >>", userType);

    return (
      <Form
        className="signUpForm"
        spellCheck="false"
        autoComplete="off"
        onSubmit={this._userSignup}
      >
        {formFields?.map((field) => this.renderInputField(field))}
        <div className=" mt-4 mb-4">
          <FormGroup className="d-flex">
            <CustomInput
              type="checkbox"
              id="termsAndCondition"
              checked={this.state.formFields.isTermsAndConditionsAccepted.value}
              onChange={(e) =>
                this._onChangeFormField(
                  "isTermsAndConditionsAccepted",
                  e.target.checked
                )
              }
            />
            <Label>
              I have read and agree to the
              <Button
                color="link"
                style={{ fontSize: "14px" }}
                onClick={() =>
                  window.open("https://www.closewise.com/terms-and-conditions/")
                }
              >
                terms and Condition{" "}
              </Button>
              <Button
                color="link"
                style={{ fontSize: "14px" }}
                onClick={() =>
                  window.open("https://www.closewise.com/privacy-policy/")
                }
              >
                And Privacy policy{" "}
              </Button>
            </Label>
          </FormGroup>
        </div>

        <Button
          color="primary"
          className="d-block w-100"
          type="submit"
          disabled={!this.state.formFields.isTermsAndConditionsAccepted.value}
        >
          Request Account
        </Button>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(SignupForm);
