import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Tooltip,
} from "reactstrap";
import { updateAgentDetailVisibility } from "../../../http/http-calls";
import { errorHandler, showToast } from "../../../helper-methods";

const CustomAgentDetailsforClient = ({ client, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    client?.agentVisibilityForClient || "yes"
  );

  const handleOptionChange = (event) => {
    console.log("Event", event.target);
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    setSelectedOption(client?.agentVisibilityForClient || "yes");
  }, [client]);

  const onSaveHandler = async () => {
    try {
      setLoading(true);
      await updateAgentDetailVisibility(client._id, {
        agentVisibilityForClient: selectedOption,
      });
      setLoading(false);
      showToast("Successfully Updated", "success");
    } catch (error) {
      console.log("Error:", error);
      errorHandler(error);
      setLoading(false);
    }
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <Card>
      {/* <CardHeader>
        <CardTitle>Agent Visibility</CardTitle>
      </CardHeader> */}
      <CardBody>
        <div
          style={{ display: "flex", justifySelf: "left", alignItems: "center" }}
        >
          <legend className="col-form-label">
            Select Agent details visibility option
          </legend>
          <i
            id="tool"
            className="fa fa-info-circle ml-2"
            style={{
              fontSize: "14px",
              color: "blue",
              cursor: "pointer",
            }}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          />
          <Tooltip
            isOpen={tooltipOpen}
            target="tool"
            toggle={toggleTooltip}
            placement="right"
          >
            On selecting 'Yes' option, the agent's email and contact number will
            be visible to the client.
          </Tooltip>
        </div>

        <FormGroup check>
          <Label for="no" check>
            <Input
              id="no"
              type="radio"
              name="toggle"
              value={"no"}
              onChange={handleOptionChange}
              checked={selectedOption === "no"}
            />{" "}
            No
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label for="yes" check>
            <Input
              id="yes"
              type="radio"
              name="toggle"
              value={"yes"}
              onChange={handleOptionChange}
              checked={selectedOption === "yes"}
            />{" "}
            Yes
          </Label>
        </FormGroup>
        <div className="text-center">
          <Button
            color="primary"
            className="pl-3 mt-3"
            onClick={() => onSaveHandler()}
            disabled={loading ? true : false}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default CustomAgentDetailsforClient;
