import React, { Suspense, lazy } from "react";
import {
  CustomInput,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  updateAgentProfileDetail,
  getAgentProfileDetail,
  getAgentSettings,
} from "../../../http/http-calls";
import { showToast, errorHandler } from "../../../helper-methods";
import SvgIcons from "../components/SvgIcons";
import SkeletonLoading from "../components/Skeletons/SkeletonLoading";
import ProcessingFeePrefrence from "./ProcessingFeePrefrence";

const EmailCustomization = lazy(() =>
  import("../components/emailCustomization")
);
const AgentAccountingAutomation = lazy(() =>
  import("../components/AgentAccountingAutomation")
);

class SettingsAgent extends React.Component {
  state = {
    loading: false,
    isEmailActive: false,
    isPushActive: false,
    emailClosingStatus: false,
    emailNewMatchingClosing: false,
    closingFeeUpdate: false,
    emailDocumentsUpload: false,
    closingAssigned: false,
    closingUnassinged: false,
    pushNewMatchingClosing: false,
    pushClosingStatus: false,
    pushDocumentsUpload: false,
    id: "",
    activeTab: "1",
    agentSettings: {},
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    processingFeePayer: "",
  };

  _goBack = () => {
    this.props.history.goBack();
  };

  _toggleTab = (active) => {
    this.setState({
      activeTab: active,
    });
  };

  componentDidMount() {
    this._getAgentProfileDetail();
    this._getAgentSettings();
  }

  _getAgentProfileDetail = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      getAgentProfileDetail()
        .then((res) => {
          let emailClosingStatus =
            res.agent?.notificationSettings?.email?.closingStatus;
          let emailNewMatchingClosing =
            res.agent?.notificationSettings?.email?.newMatchingClosing;
          let closingFeeUpdate =
            res.agent?.notificationSettings?.email?.closingFeeUpdate;
          let emailDocumentsUpload =
            res.agent?.notificationSettings?.email?.documentsUpload;

          let closingAssigned =
            res.agent?.notificationSettings?.email?.closingAssigned;
          let closingUnassinged =
            res.agent?.notificationSettings?.email?.closingUnassinged;

          let pushClosingStatus =
            res.agent?.notificationSettings?.push?.closingStatus;
          let pushDocumentsUpload =
            res.agent?.notificationSettings?.push?.documentsUpload;
          let pushNewMatchingClosing =
            res.agent?.notificationSettings?.push?.newMatchingClosing;

          let id = res.agent._id;
          let processingFeePayer = res.agent?.processingFeePayer;

          let isEmailActive =
            emailClosingStatus &&
            emailNewMatchingClosing &&
            closingFeeUpdate &&
            emailDocumentsUpload &&
            closingAssigned &&
            closingUnassinged;
          let isPushActive =
            pushClosingStatus && pushDocumentsUpload && pushNewMatchingClosing;

          this.setState({
            emailClosingStatus,
            emailNewMatchingClosing,
            closingFeeUpdate,
            emailDocumentsUpload,
            closingAssigned,
            closingUnassinged,

            pushNewMatchingClosing,
            pushClosingStatus,
            pushDocumentsUpload,
            processingFeePayer,

            isEmailActive,
            isPushActive,
            id,
            loading: false,
          });
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
          reject(error);
        });
    });
  };

  _getAgentSettings = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });
      getAgentSettings()
        .then((res) => {
          this.setState({
            agentSettings: res.agentSetting,
            loading: false,
          });
          resolve(res);
        })
        .catch((error) => {
          this.setState({ loading: false });
          reject(error);
        });
    });
  };

  // function to update agentProfile details
  _updateAgentProfileDetail = (id, data) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      updateAgentProfileDetail(id, data)
        .then(async (res) => {
          await this._getAgentProfileDetail();

          showToast("Settings Updated", "success");
          this.setState({ loading: false }, () => resolve(true));
        })
        .catch((error) => {
          errorHandler(error);

          this.setState({ loading: false }, () => resolve(false));
        });
    });
  };

  // function to toggle email or push notification switch
  _handleClick = (fieldName, value) => {
    if (fieldName === "isEmailActive") {
      this.setState(
        {
          isEmailActive: value,
          emailClosingStatus: value,
          emailNewMatchingClosing: value,
          closingFeeUpdate: value,
          emailDocumentsUpload: value,
          closingAssigned: value,
          closingUnassinged: value,
        },
        () => this._updateProfile()
      );
    } else if (fieldName === "isPushActive") {
      this.setState(
        {
          isPushActive: value,
          pushNewMatchingClosing: value,
          pushClosingStatus: value,
          pushDocumentsUpload: value,
        },
        () => this._updateProfile()
      );
    } else {
      this.setState({ [fieldName]: value }, () => {
        this._updateProfile();
      });
    }
  };

  _updateProfile = () => {
    let data = {
      notificationSettings: {
        email: {
          closingStatus: this.state.emailClosingStatus,
          documentsUpload: this.state.emailDocumentsUpload,
          newMatchingClosing: this.state.emailNewMatchingClosing,
          closingFeeUpdate: this.state.closingFeeUpdate,
          closingAssigned: this.state.closingAssigned,
          closingUnassinged: this.state.closingUnassinged,
        },
        push: {
          closingStatus: this.state.pushClosingStatus,
          documentsUpload: this.state.pushDocumentsUpload,
          newMatchingClosing: this.state.pushNewMatchingClosing,
        },
      },
    };

    this._updateAgentProfileDetail(this.state.id, data);
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  render() {
    const { loading, activeTab } = this.state;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Settings</h2>
          </div>
          <div className="verticalTabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => this._toggleTab("1")}
                >
                  <SvgIcons type={"notification"} />
                  Notification
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => this._toggleTab("2")}
                >
                  <SvgIcons type={"email_enevlope"} />
                  Email Customization
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => this._toggleTab("3")}
                >
                  <SvgIcons type={"accountingAutomation"} />
                  Accounting Automation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => this._toggleTab("4")}
                >
                  <SvgIcons type={"accountingAutomation"} />
                  Processing Fee Preferences
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {!loading ? (
                  <>
                    <Card className="settingCard">
                      <CardHeader
                        // on clicking this element, convert all related fields to !(previous value)
                        onClick={() =>
                          this._handleClick(
                            "isEmailActive",
                            !this.state.isEmailActive
                          )
                        }
                      >
                        <div>
                          <CardTitle tag="h6">Email Notifications</CardTitle>
                          <p>Receive E-mails About</p>
                        </div>
                        <CustomInput
                          type="switch"
                          id="custom_input_switch_isEmailActive"
                          label=""
                          disabled={loading}
                          checked={this.state.isEmailActive}
                          onChange={() => {}}
                        />
                      </CardHeader>

                      <CardBody>
                        <ul>
                          <li
                            onClick={() =>
                              this._handleClick(
                                "emailNewMatchingClosing",
                                !this.state.emailNewMatchingClosing
                              )
                            }
                          >
                            New Matching Closing
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_emailNewMatchingClosing"
                              label=""
                              disabled={loading}
                              checked={this.state.emailNewMatchingClosing}
                              onChange={() => {}}
                            />
                          </li>

                          <li
                            onClick={() =>
                              this._handleClick(
                                "emailClosingStatus",
                                !this.state.emailClosingStatus
                              )
                            }
                          >
                            Closing Status Updated by Signing Company
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_emailClosingStatus"
                              label=""
                              disabled={loading}
                              checked={this.state.emailClosingStatus}
                              onChange={() => {}}
                            />
                          </li>

                          <li
                            onClick={() =>
                              this._handleClick(
                                "closingAssigned",
                                !this.state.closingAssigned
                              )
                            }
                          >
                            Closing Assigned
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_closingAssigned"
                              label=""
                              disabled={loading}
                              checked={this.state.closingAssigned}
                              onChange={() => {}}
                            />
                          </li>

                          <li
                            onClick={() =>
                              this._handleClick(
                                "closingUnassinged",
                                !this.state.closingUnassinged
                              )
                            }
                          >
                            Closing Unassigned
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_closingUnassinged"
                              label=""
                              disabled={loading}
                              checked={this.state.closingUnassinged}
                              onChange={() => {}}
                            />
                          </li>

                          <li
                            onClick={() =>
                              this._handleClick(
                                "emailDocumentsUpload",
                                !this.state.emailDocumentsUpload
                              )
                            }
                          >
                            Documents Uploaded
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_emailDocumentsUpload"
                              label=""
                              disabled={loading}
                              checked={this.state.emailDocumentsUpload}
                              onChange={() => {}}
                            />
                          </li>

                          <li
                            onClick={() =>
                              this._handleClick(
                                "closingFeeUpdate",
                                !this.state.closingFeeUpdate
                              )
                            }
                          >
                            Fee Updated
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_closingFeeUpdate"
                              label=""
                              disabled={loading}
                              checked={this.state.closingFeeUpdate}
                              onChange={() => {}}
                            />
                          </li>
                        </ul>
                      </CardBody>
                    </Card>

                    <Card className="settingCard">
                      <CardHeader
                        // on clicking this element, convert all related fields to !(previous value)
                        onClick={() =>
                          this._handleClick(
                            "isPushActive",
                            !this.state.isPushActive
                          )
                        }
                      >
                        <div>
                          <CardTitle tag="h6">Push Notifications</CardTitle>
                          <p>Receive Website Alerts About</p>
                        </div>
                        <CustomInput
                          type="switch"
                          id="custom_input_switch_isPushActive"
                          label=""
                          disabled={loading}
                          checked={this.state.isPushActive}
                          onChange={() => {}}
                        />
                      </CardHeader>
                      <CardBody>
                        <ul>
                          <li
                            onClick={() =>
                              this._handleClick(
                                "pushDocumentsUpload",
                                !this.state.pushDocumentsUpload
                              )
                            }
                          >
                            Documents Uploaded
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_pushDocumentsUpload"
                              label=""
                              disabled={loading}
                              checked={this.state.pushDocumentsUpload}
                              onChange={() => {}}
                            />
                          </li>
                          <li
                            onClick={() =>
                              this._handleClick(
                                "pushNewMatchingClosing",
                                !this.state.pushNewMatchingClosing
                              )
                            }
                          >
                            New Closing Available
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_pushNewMatchingClosing"
                              label=""
                              disabled={loading}
                              checked={this.state.pushNewMatchingClosing}
                              onChange={() => {}}
                            />
                          </li>
                          <li
                            onClick={() =>
                              this._handleClick(
                                "pushClosingStatus",
                                !this.state.pushClosingStatus
                              )
                            }
                          >
                            Closing Status Updated
                            <CustomInput
                              type="switch"
                              id="custom_input_switch_pushClosingStatus"
                              label=""
                              disabled={loading}
                              checked={this.state.pushClosingStatus}
                              onChange={() => {}}
                            />
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </>
                ) : (
                  <SkeletonLoading type="agentSettings" count={1} />
                )}
              </TabPane>
              <TabPane tabId="2">
                <Suspense fallback={<></>}>
                  <EmailCustomization />
                </Suspense>
              </TabPane>
              <TabPane tabId="3">
                <Suspense fallback={<></>}>
                  <AgentAccountingAutomation
                    agentSettings={this.state.agentSettings}
                    getAgentSettings={this._getAgentSettings}
                  />
                </Suspense>
              </TabPane>

              <TabPane tabId="4">
                <Suspense fallback={<></>}>
                  <ProcessingFeePrefrence
                    agentId={this.state.id}
                    feePrefrence={this.state.processingFeePayer}
                    // getAgentSettings={this._getAgentSettings}
                  />
                </Suspense>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </>
    );
  }
}

export default SettingsAgent;
