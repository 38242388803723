import React, { Component, Suspense } from "react";
import { Row, Col, Button, Input, FormGroup, Label } from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import AddMileageReportingAgentModal from "../components/add-mileage-reporting-agent-modal";
import {
  deepClone,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatNumberInShort,
  getDateRangeValue,
  getLoggedInUserId,
  openGoogleMapOnNewTab,
  openUrlOnNewTab,
  showToast,
  structureQueryParams,
  isRegularUser,
  formatDateOnlyAsPerTimeZone,
  formatOnlyDateMoment,
  convertIsoToUtc,
  refreshFunc,
  errorHandler,
} from "../../../helper-methods";
import { agentGetAllMileages } from "../../../http/http-calls";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import {
  BASE_URL,
  customDateRangeConfig,
  monthShortNames,
} from "../../../config";
import CustomTable from "../components/CustomTable";
import BarGraph from "../components/graph/BarGraph";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomCard from "../components/CustomCard";
import { Link } from "react-router-dom";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { mileageHeaderKeys } from "../../../config/stateConfig";
import MileageLogReportingAgentCardDataFormat from "../components/MileageLogReportingAgentCardDataFormat";
import CommentModal from "../components/Modals/Agents/CommentModal";

class MileageLogReportingAgent extends Component {
  state = {
    mileageGraphData: {
      colors: ["#4C96CE"],
      tooltipName1: "Miles",
      series1: [],
      formatter1: (value) => formatNumberInShort(value),
    },
    mileageHeaderKeys: mileageHeaderKeys,
    mileageCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    mileageList: [],
    mileageTotalCount: 0,
    filters: {
      customDateRangeValue: "year",
      dateRangeValue: null,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    addMileageReportingAgentModal: {
      isOpen: false,
      data: null,
    },
    loading: {
      showTableLoading: false,
    },
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    isClassAdded: false,
    AgentcommentModal: {
      isOpen: false,
      data: "",
    },
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    // this._setDateRangeValue();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("agent-reporting-mileage-log");
    });
    HeaderEventEmitter.subscribe("add-mileage", () => {
      console.log("add mileage");
      this._onToggleAddMileageReportingAgentModal(true); // open modal to add agent mileage reporting
    });
  };

  // set Income Graph Data from response mileageGraph
  _setIncomeGraphData = (resData = []) => {
    try {
      const { mileageGraphData, filters } = deepClone(this.state);

      // Loop through it and set label using day and month key and value as miles
      mileageGraphData.series1 = resData?.length
        ? resData.map((each) => {
            let label = "";
            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.miles };
          })
        : [];

      this.setState({ mileageGraphData });
    } catch (error) {
      showToast("Somthing went wrong. Try again after sometime.", "error");
    }
  };

  _agentGetAllMileages = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig } = deepClone(this.state);

    const filterPayload = {};

    if (filters.dateRangeValue) {
      filterPayload["startDate"] = formatOnlyDateMoment(
        filters.dateRangeValue[0]
      );
      filterPayload["endDate"] = formatOnlyDateMoment(
        filters.dateRangeValue[1]
      );
    }
    if (filters.customDateRangeValue) {
      if (
        filters.customDateRangeValue === "month" ||
        filters.customDateRangeValue === "day" ||
        filters.customDateRangeValue === "week"
      ) {
        filterPayload["groupByUnit"] = "day";
      } else if (filters.customDateRangeValue === "allTime") {
        filterPayload["groupByUnit"] = "year";
      }
    }

    tableConfig["filters"] = { ...filterPayload };

    agentGetAllMileages(tableConfig)
      .then((res) => {
        this._setIncomeGraphData(res?.mileageGraph);

        this.setState(
          {
            mileageList: res?.mileages,
            mileageTotalCount: res?.totalCount,
          },
          () => this._manageLoading("showTableLoading", false)
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._agentGetAllMileages();
    });
  };

  _setDateRangeValue = (value = "year") => {
    const { filters } = this.state;

    switch (value) {
      case "day":
      case "week":
      case "month":
      case "year": {
        filters["dateRangeValue"] = getDateRangeValue(value);
        break;
      }
      case "allTime": {
        filters["dateRangeValue"] = null;
        break;
      }
      case "customDateRange": {
        filters["dateRangeValue"] = null;
        filters["customDateRangeValue"] = value;
        this.setState({ filters });
        return;
      }
      default: {
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }
    }

    filters["customDateRangeValue"] = value;
    this.setState({ filters }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    if (type === "dateRangeValue") {
      if (value) {
        filters["customDateRangeValue"] = "customDateRange";
        filters[type] = value;
        this.setState({ filters }, () => {
          this._paginate();
          this._persistFilter();
        });
      } else {
        this._setDateRangeValue();
      }
    } else {
      filters[type] = value;
      this.setState({ filters }, () => {
        this._paginate();
        this._persistFilter();
      });
    }
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          customDateRangeValue: "year",
          dateRangeValue: null,
        },
      },
      () => {
        this._setDateRangeValue();
        refreshFunc("agent-reporting-mileage-log-responsive");
      }
    );
  };

  _onToggleAddMileageReportingAgentModal = (isOpen = false, data = null) => {
    this.setState({
      addMileageReportingAgentModal: {
        isOpen,
        data,
      },
    });
  };

  _redirectToClosingDetailPage = (closingId) => {
    if (!closingId) {
      showToast("No Closing found");
      return;
    }
    if (closingId)
      this.props.history.push(`/agent/closing-details-for-agents/${closingId}`);
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "travelDate": {
        return cell ? (
          <div style={{ width: 100 }}>
            {formatDateOnlyAsPerTimeZone(
              cell,
              row?._closing?.closingAddress?.timeZone
            )}
          </div>
        ) : (
          "N/A"
        );
      }
      case "purpose": {
        return row?.purpose ? (
          <>
            <div style={{ width: 100 }}>
              <Button
                className="tableLink"
                color="link"
                onClick={() =>
                  this._redirectToClosingDetailPage(row?._closing?.id)
                }
              >
                {row?.purpose}
              </Button>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "startAddress":
      case "stopAddress":
      case "endAddress": {
        return cell ? (
          <>
            <div className="locationWrap">
              <span>{formatAddressInSingleText(cell)}</span>
              <Button
                color="link"
                title={formatAddressInSingleText(cell)}
                onClick={() => openGoogleMapOnNewTab(cell)}
              >
                <img
                  src={require("../../../assets/img/map-marker.svg").default}
                  alt="map icon"
                />
                Map
              </Button>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "distance": {
        // return cell || cell === 0 ? formatNumberInShort(cell) : "N/A";
        return row?.distance || row?.distance === 0
          ? Number(row?.distance) < 1
            ? row?.distance
            : formatNumberInShort(row?.distance)
          : "N/A";
      }
      case "amount": {
        return cell || cell === 0 ? formatCurrencyValue(cell) : "N/A";
      }
      case "comments": {
        return cell ? (
          <Button
            color="link"
            className=""
            onClick={() => this._onToggleCommentsModal(true, cell)}
          >
            <img
              src={require("../../../assets/img/nodes.svg").default}
              alt="comments"
            />
          </Button>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return (
          <Button
            color="link"
            onClick={() =>
              this._onToggleAddMileageReportingAgentModal(true, row)
            }
          >
            <img
              src={require("../../../assets/img/pencil.svg").default}
              alt="action"
            />
          </Button>
        );
      }
      default: {
        return cell;
      }
    }
  };
  _onToggleCommentsModal = (isOpen = false, comment = "") => {
    this.setState({
      AgentcommentModal: {
        isOpen: isOpen,
        data: comment,
      },
    });
  };

  _downloadData = (action = "download") => {
    const { mileageList, filters } = this.state;

    if (!mileageList.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (filters.dateRangeValue) {
      payload["startDate"] = formatOnlyDateMoment(filters.dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(filters.dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(`${BASE_URL}/agent/download/mileages${queryParams}`);
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _persistFilter = () => {
    const { filters, tableConfig } = this.state;
    if (
      (filters.customDateRangeValue !== "month" &&
        filters.customDateRangeValue) ||
      tableConfig.pageNumber !== 1
    ) {
      const data = { filters, tableConfig };
      localStorage.agentmileage = JSON.stringify(data);
    } else {
      delete localStorage.agentmileage;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentmileage) {
      try {
        const filters = JSON.parse(localStorage.agentmileage);

        let dateRange = null;
        if (filters?.filters?.dateRangeValue) {
          dateRange = [
            new Date(convertIsoToUtc(filters?.filters?.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters?.filters?.dateRangeValue[1])),
          ];
        }
        this.setState(
          {
            dateRangeValue: dateRange,
            filters: {
              dateRangeValue: dateRange,
              customDateRangeValue: filters?.filters?.customDateRangeValue,
            },
            tableConfig: filters?.tableConfig,
          },
          () => {
            this._agentGetAllMileages();
          }
        );
      } catch (e) {
        this._setDateRangeValue();
      }
    } else {
      this._setDateRangeValue();
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    // console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userContent">
                <span>File Number</span>
                {row?.purpose ? (
                  <Button
                    className="tableLink fs-12 pl-0"
                    color="link"
                    onClick={() =>
                      this._redirectToClosingDetailPage(row?._closing?.id)
                    }
                  >
                    {row?.purpose}
                  </Button>
                ) : (
                  "N/A"
                )}
              </div>
            </div>

            <Button
              color="link"
              onClick={() =>
                this._onToggleAddMileageReportingAgentModal(true, row)
              }
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="action"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return <MileageLogReportingAgentCardDataFormat row={row} />;
  };

  render() {
    const {
      filters,
      addMileageReportingAgentModal,
      mileageGraphData,
      mileageList,
      mileageTotalCount,
      mileageHeaderKeys,
      loading,
      tableConfig,
      upgradeAccountModal,
      isClassAdded,
      mileageCardHeaderKeys,
      AgentcommentModal,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Mileage Log</h2>

          <div className="rightSide">
            <Button color="link" onClick={this._resetFilter}>
              <img
                id="agent-reporting-mileage-log-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>
            <Link to={`/agent/bulk-upload?milageLog`} className="ml-2">
              Bulk upload
            </Link>
            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
            {/* if agent has any plan pro-plus or premium then only 
            mileage can be added otherwise upgrade account modal will open */}
            <Button
              className="floatingButton"
              color="primary"
              onClick={() =>
                isRegularUser()
                  ? this._onToggleAddMileageReportingAgentModal(true)
                  : this._toggleUpgradeAccountModal(true)
              }
            >
              Add Mileage
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={this.handleButtonClick}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>
        <div
          className={`filterContainer responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            {/* if agent has any plan pro-plus or premium then only 
              they can set date range 
            */}
            <FormGroup className="dateRange">
              <Label>Date Range</Label>
              <DateRangePicker
                isOpen={
                  !filters.dateRangeValue &&
                  filters.customDateRangeValue === "customDateRange"
                    ? true
                    : false
                }
                clearIcon={null}
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                onChange={(dateRangeValue) =>
                  isRegularUser()
                    ? this._filterOnChange("dateRangeValue", dateRangeValue)
                    : this._toggleUpgradeAccountModal(true)
                }
                value={filters.dateRangeValue}
              />
            </FormGroup>
            <FormGroup>
              <Label>Date Added</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  value={filters.customDateRangeValue}
                  name="customDateRangeValue"
                  onChange={(e) => this._setDateRangeValue(e.target.value)}
                >
                  {customDateRangeConfig.map((obj) => (
                    <option key={obj.value} value={obj.value}>
                      {obj.label}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>
            <Button
              color="link"
              outline
              className="downloadButton ml-auto"
              onClick={() => this._downloadData()}
            >
              Download
              <img
                src={require(`../../../assets/img/download_blue.png`)}
                alt="download"
              />
            </Button>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={this.filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        <div className="downloadButtonPos">
          <Button color="link" outline onClick={() => this._downloadData()}>
            Download
            <img
              src={require(`../../../assets/img/download_blue.png`)}
              alt="download"
            />
          </Button>
        </div>
        <Row>
          <Col md="12">
            {/* if agent has any plan pro-plus or premium then only 
              we will show graph
            */}
            {isRegularUser() && mileageTotalCount ? (
              mileageGraphData?.series1?.length ? (
                <div className="graphWrap">
                  <h1>Total Mileage</h1>
                  {/* bar graph * */}
                  {isRegularUser() ? (
                    <BarGraph
                      data={mileageGraphData}
                      className="barGraphWrap"
                      height={500}
                    />
                  ) : (
                    <h6>Upgrade to unlock.</h6>
                  )}
                </div>
              ) : (
                <div className="nodata">
                  <img
                    src={require("../../../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display on chart.</p>
                </div>
              )
            ) : null}

            {isForMobile ? (
              <Suspense fallback={<></>}>
                <div className="hideDesktop">
                  <CustomCard
                    isPageStartFromOne={true}
                    pageNumber={tableConfig.pageNumber}
                    tableData={mileageList}
                    headerKeys={mileageCardHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={mileageTotalCount}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    rowSelection={false}
                    showTableLoading={loading.showTableLoading}
                    cardHeaderFormat={this._cardHeaderFormat}
                    cardDataFormat={this._cardDataFormat}
                  />
                </div>
              </Suspense>
            ) : (
              <Suspense fallback={<></>}>
                <div className="hideMobile">
                  {mileageHeaderKeys && mileageHeaderKeys.length && (
                    <CustomTable
                      striped
                      isPageStartFromOne={true}
                      pageNumber={tableConfig.pageNumber}
                      pageSize={tableConfig.limit}
                      rowSelection={false}
                      tableData={mileageList}
                      headerKeys={mileageHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={mileageTotalCount}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      showTableLoading={loading.showTableLoading}
                    />
                  )}
                </div>
              </Suspense>
            )}
          </Col>
        </Row>

        {/* add mileage reporting modal for adding mileage covered between location */}
        <AddMileageReportingAgentModal
          isOpen={addMileageReportingAgentModal.isOpen}
          data={addMileageReportingAgentModal.data}
          toggle={() => this._onToggleAddMileageReportingAgentModal()}
          resetDetails={() => this._paginate()}
        />

        <CommentModal
          isOpen={AgentcommentModal.isOpen}
          data={AgentcommentModal.data}
          toggle={() => this._onToggleCommentsModal()}
        />

        {upgradeAccountModal.isOpen && (
          <UpgradeAccountModal
            {...this.props}
            isOpen={upgradeAccountModal.isOpen}
            toggle={this._toggleUpgradeAccountModal}
          />
        )}
      </div>
    );
  }
}

export default ScreenWidthHOC(MileageLogReportingAgent);
