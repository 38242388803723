/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Redirect, Route, Switch } from "react-router-dom";

import AdminNavbar from "../components/AdminNavbar";
import Footer from "../components/Footer.js";
import Sidebar from "../components/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import { signingCompanyRoutes } from "../../../routes";
// import ProtectedRoute from "../../general/components/protected-route";
import BillingCompany from "../pages/billing-company";
import ClosingDetails from "../pages/closing-details";
import Subscription from "../pages/subscription";
import AgentDetails from "../pages/agent-details";
import Notifications from "../pages/notifications";
import ContactUs from "../pages/contact-us";
import CreateClosing from "../pages/create-closing";
import FAQCOMPANY from "../pages/faq-company";
import Settings from "../pages/settings";
import ProtectedRoute from "../../general/components/protected-route";
import SecuredRoute from "../../general/components/secured-route";
import { connect } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import MediaQueueViewer from "../containers/media-queue-viewer";
import OrderDetails from "../pages/OrderDetails";
import CreateOrder from "../pages/CreateOrder";
import ClientDetails from "../pages/ClientDetails";
import Help from "../pages/HelpModule/Help";
import TopicList from "../pages/HelpModule/TopicList";
import TopicDetails from "../pages/HelpModule/TopicDetails";
import BulkUpload from "../pages/bulk-upload";
import Gleap from "gleap";
import { GLEAP_COMPANY_ID } from "../../../config/index.js";
import ReKYCCard from "../components/re-kycalert.jsx";
import {
  signingCompanyDetail,
  updateSigningCompanyProfile,
} from "../../../http/http-calls.js";
import { errorHandler } from "../../../helper-methods/index.js";
import AgentCompanyCreditCard from "../pages/AgentPages/AgentCompanyCreditCard.jsx";
import CompanyProfile from "../pages/company-profile.jsx";

class CompanyLayout extends React.Component {
  state = {
    backgroundColor: "black",
    activeColor: "info",
    sidebarMini: false,
  };

  componentDidMount() {
    Gleap.initialize(GLEAP_COMPANY_ID);

    // Show Gleap in this page
    Gleap.showFeedbackButton(true);

    // if (navigator.platform.indexOf("Win") > -1) {
    //   document.documentElement.className += " perfect-scrollbar-on";
    //   document.documentElement.classList.remove("perfect-scrollbar-off");
    //   ps = new PerfectScrollbar(this.refs.mainPanel);
    // }

    this._getSigningCompanyDetail();
  }

  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //     document.documentElement.className += " perfect-scrollbar-off";
  //     document.documentElement.classList.remove("perfect-scrollbar-on");
  //   }
  // }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes) => {
    const { userData } = this.props;

    // eslint-disable-next-line array-callback-return
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }

      if (userData?.user?.isAssistant && prop.name !== "Company Profile") {
        return (
          <SecuredRoute
            path={prop.path}
            component={prop.component}
            key={key}
            redirectRoute="/signingcompany/profile"
          />
        );
      }
      if (prop.name !== "Subscription" && !userData?.user?.isAssistant) {
        return (
          <SecuredRoute
            path={prop.path}
            component={prop.component}
            key={key}
            redirectRoute="/signingcompany/subscription"
          />
        );
      }
    });
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  _getSigningCompanyDetail = () => {
    signingCompanyDetail()
      .then((res) => {
        this.props.updateUserObj(res?.signingCompany);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _hideReKYCBanner = async () => {
    const { userData } = this.props;

    let response = await updateSigningCompanyProfile(userData.user.id, {
      showKycBanner: false,
    });
    if (response) {
      this.props.updateUserObj({ showKycBanner: false });

      // this.setState({
      // showKycBanner: false,
      // });
    }
    try {
    } catch (error) {
      errorHandler(error);
    }
  };

  render() {
    const { userData } = this.props;

    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={signingCompanyRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            userData={userData}
            handleMiniClick={this.handleMiniClick}
          />
          {userData?.user?.showKycBanner ? (
            <ReKYCCard
              hideReKYCBanner={() =>
                this.props.updateUserObj({ showKycBanner: false })
              }
              doNotShowAgain={() => this._hideReKYCBanner()}
            />
          ) : null}
          <Switch>
            {this.getRoutes(signingCompanyRoutes)}
            {/* If subscription expired that handled from securedRoute check this component isCardAdded check all things */}

            <SecuredRoute
              path={`${this.props.match.path}/closing-details/:id`}
              component={ClosingDetails}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <SecuredRoute
              path={`${this.props.match.path}/create-closing`}
              component={CreateClosing}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <SecuredRoute
              path={`${this.props.match.path}/order-details/:id`}
              component={OrderDetails}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <SecuredRoute
              path={`${this.props.match.path}/create-order`}
              component={CreateOrder}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <SecuredRoute
              path={`${this.props.match.path}/agent-details/:id`}
              component={AgentDetails}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <SecuredRoute
              path={`${this.props.match.path}/notifications`}
              component={Notifications}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <SecuredRoute
              path={`${this.props.match.path}/contact`}
              component={ContactUs}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <ProtectedRoute
              path={`${this.props.match.path}/subscription`}
              component={Subscription}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/profile`}
              component={CompanyProfile}
              redirectRoute={"/login"}
            />

            <SecuredRoute
              path={`${this.props.match.path}/billing`}
              component={BillingCompany}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <ProtectedRoute
              path={`${this.props.match.path}/credit-card`}
              component={AgentCompanyCreditCard}
              redirectRoute={
                userData?.user?.isAssistant
                  ? "/signingcompany/profile"
                  : "/signingcompany/subscription"
              }
            />

            <ProtectedRoute
              path={`${this.props.match.path}/faq`}
              component={FAQCOMPANY}
              redirectRoute={"/login"}
            />

            <SecuredRoute
              path={`${this.props.match.path}/settings`}
              component={Settings}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/client-details/:id`}
              component={ClientDetails}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/help`}
              component={Help}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/topic-list/:id`}
              component={TopicList}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/topic/:id`}
              component={TopicDetails}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/faq-details/:id`}
              component={FAQCOMPANY}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              path={`${this.props.match.path}/bulk-upload`}
              component={BulkUpload}
              redirectRoute={"/login"}
            />
            {/* <Redirect from="/" to="/login" /> */}
            <Route
              path="/"
              render={() => <Redirect to="/signingcompany/closing-dashboard" />}
            />
          </Switch>
          <MediaQueueViewer />
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid userData={userData} />
            )
          }
        </div>
        {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
          handleMiniClick={this.handleMiniClick}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (userData) => dispatch(updateUserObj(userData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyLayout);
