import React, { useState, useEffect } from "react";
import { Card, CardBody, FormGroup, Input, Label, Button } from "reactstrap";
import { updateAgentProcessingFee } from "../../../http/http-calls";
import { errorHandler, showToast } from "../../../helper-methods";

const ProcessingFeePrefrence = ({ agentId, feePrefrence }) => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(feePrefrence);
  console.log("client  details  ", agentId, feePrefrence);

  const handleOptionChange = (event) => {
    console.log("Event", event.target);
    setSelectedOption(event.target.name);
  };
  useEffect(() => {
    setSelectedOption(feePrefrence);
  }, [feePrefrence]);

  const onSaveHandler = async () => {
    try {
      setLoading(true);
      await updateAgentProcessingFee(agentId, {
        processingFeePayer: selectedOption,
      });
      setLoading(false);
      showToast("Settings Updated", "success");
    } catch (error) {
      console.log("Error:", error);
      setLoading(false);
      errorHandler(error);
    }
  };
  return (
    <Card>
      <CardBody>
        <legend className="col-form-label">
          Manage Processing Fee Responsibility
        </legend>
        <FormGroup check>
          <Label for="Client" check>
            <Input
              id="Client"
              type="radio"
              name="Client"
              value={selectedOption}
              onChange={handleOptionChange}
              checked={selectedOption === "Client"}
            />{" "}
            Client
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label for="Agent" check>
            <Input
              id="Agent"
              type="radio"
              name="Agent"
              value={selectedOption}
              onChange={handleOptionChange}
              checked={selectedOption === "Agent"}
            />{" "}
            Agent
          </Label>
        </FormGroup>

        <div className="text-center">
          <Button
            color="primary"
            className="pl-3 mt-3"
            onClick={() => onSaveHandler()}
            disabled={loading ? true : false}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProcessingFeePrefrence;
