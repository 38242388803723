import React from "react";
import { Col, Row } from "reactstrap";
import BarGraph from "./graph/BarGraph";
import LineGraph from "./graph/LineGraph";
import SkeletonLoading from "./Skeletons/SkeletonLoading";
import useScreenWidth from "./HelpModule/useScreenWidth";

const AgentCompanySummariesGraph = ({
  orderGraphData,
  incomeGraphData,
  agentPayPeriodGraphData,
  expenseAndNetGraphData,
  userData,
  loading,
}) => {
  const { isForMobile } = useScreenWidth();

  return (
    <>
      <Row>
        <Col md="6" className="mb-4">
          {/* closing graph */}
          {loading ? (
            <SkeletonLoading
              type="card"
              count={1}
              height={isForMobile ? 194 : 497}
              width={isForMobile ? 350 : 750}
            />
          ) : orderGraphData?.series1?.length ? (
            <div className="graphWrap h-100">
              <h1>Order</h1>
              <BarGraph data={orderGraphData} height={400} />
            </div>
          ) : (
            <div className="graphWrap noData h-100">
              <img
                src={require("../../../assets/img/noData.svg").default}
                alt="no data"
              />

              <p>There is no closing data to display on chart.</p>
            </div>
          )}
        </Col>

        {userData?.type === "agent" ||
        (!userData?.user?.isTitleCompany &&
          userData?.type === "signingcompany") ? (
          <Col md="6" className="mb-4">
            {/* income graph */}
            {loading ? (
              <SkeletonLoading
                type="card"
                count={1}
                height={isForMobile ? 194 : 497}
                width={isForMobile ? 350 : 750}
              />
            ) : incomeGraphData?.series1?.length ? (
              <div className="graphWrap h-100">
                <h1>Income</h1>
                <BarGraph data={incomeGraphData} height={400} />
              </div>
            ) : (
              <div className="graphWrap noData h-100">
                <img
                  src={require("../../../assets/img/noData.svg").default}
                  alt="no data"
                />

                <p>There is no income data to display on chart.</p>
              </div>
            )}
          </Col>
        ) : null}

        {userData?.type === "signingcompany" && (
          <Col md="6" className="mb-4">
            {/* agent pay period graph */}
            {agentPayPeriodGraphData?.series1?.length ? (
              <div className="graphWrap h-100">
                <h1>Agent Pay Period Graph</h1>
                <BarGraph data={agentPayPeriodGraphData} height={400} />
              </div>
            ) : (
              <div className="graphWrap noData h-100">
                <img
                  src={require("../../../assets/img/noData.svg").default}
                  alt="no data"
                />

                <p>There is no Agent Pay Period data to display on chart.</p>
              </div>
            )}
          </Col>
        )}

        <Col md="12">
          {/* expense & net graph */}
          {loading ? (
            <SkeletonLoading
              type="card"
              count={1}
              height={isForMobile ? 194 : 497}
              width={isForMobile ? 350 : 750}
            />
          ) : expenseAndNetGraphData?.series1?.length ||
            expenseAndNetGraphData?.series2?.length ? (
            <div className="graphWrap">
              <h1>Expenses and Net Profit</h1>
              <LineGraph data={expenseAndNetGraphData} height={400} />
            </div>
          ) : (
            <div className="graphWrap noData h-100">
              <img
                src={require("../../../assets/img/noData.svg").default}
                alt="no data"
              />

              <p>There is no expense data to display on chart.</p>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AgentCompanySummariesGraph;
