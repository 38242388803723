import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
const DeleteModal = ({
  isOpen,
  toggle,
  deleteExpense,
  isLoaderActive,
  title,
  warning,
}) => {
  return (
    <Modal centered isOpen={isOpen} toggle={() => toggle(null)}>
      <ModalHeader toggle={() => toggle(null)}>Delete</ModalHeader>
      <ModalBody>
        <h5 style={{ textAlign: "center", fontWeight: 600, fontSize: 14 }}>
          {" "}
          Do You Want To Delete This {title}? <br />
        </h5>
        <h6 style={{ textAlign: "center" }}> {warning}</h6>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => toggle(null)}>
          Cancel
        </Button>

        <Button
          color="primary"
          // disabled={error || isLoaderActive ? true : false}
          onClick={() => {
            deleteExpense();
          }}
        >
          {isLoaderActive ? <i className="fa fa-spinner fa-spin" /> : null}
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
