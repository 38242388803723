import React, { useState, useEffect } from "react";
import { Card, CardBody, FormGroup, Input, Label, Button } from "reactstrap";

import { errorHandler, showToast } from "../../../helper-methods";
import { updatePaymentVisibility } from "../../../http/http-calls";

const ClientPaymentVisibility = ({ client, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(
    client?.sendInvoiceLinkStatus || "yes"
  );
  // console.log("client   priyanka details  ", client);

  const handlePaymentOptionChange = (event) => {
    console.log("Event", event.target);
    setSelectedPaymentOption(event.target.value);
  };
  useEffect(() => {
    setSelectedPaymentOption(client?.sendInvoiceLinkStatus || "yes");
  }, [client]);

  const onSaveHandler = async () => {
    try {
      setLoading(true);

      await updatePaymentVisibility(client._id, {
        sendInvoiceLinkStatus: selectedPaymentOption,
      });
      setLoading(false);
      showToast("Successfully Updated", "success");
    } catch (error) {
      console.log("Error:", error);
      errorHandler(error);
      setLoading(false);
    }
  };
  return (
    <Card>
      {/* <CardHeader>
        <CardTitle>Payment Visibility</CardTitle>
      </CardHeader> */}
      <CardBody>
        <legend className="col-form-label">
          Do you want to show the payment option in the invoice PDF?
        </legend>
        <FormGroup check>
          <Label for={"no"} check>
            <Input
              type="radio"
              name="false"
              value={"no"}
              onChange={handlePaymentOptionChange}
              checked={selectedPaymentOption === "no"}
            />{" "}
            No
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label for={true} check>
            <Input
              type="radio"
              name="true"
              value={"yes"}
              onChange={handlePaymentOptionChange}
              checked={selectedPaymentOption === "yes"}
            />{" "}
            Yes
          </Label>
        </FormGroup>

        <div className="text-center">
          <Button
            color="primary"
            className="h-auto mt-3"
            onClick={() => onSaveHandler()}
            disabled={loading ? true : false}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default ClientPaymentVisibility;
