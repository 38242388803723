import React, { Suspense, lazy } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader,
  CardTitle,
  Card,
  CardBody,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import {
  createAgentClosing,
  uploadClosingPdf,
  getClosingDetailsById,
  getAgentClient,
  updateAgentClosingById,
  getRonPlatformTypes,
  FileNumberDuplicateMatchCheck,
} from "../../../http/http-calls";
import {
  deepClone,
  formatDateAndTime,
  getFileExtension,
  getPhoneNumberFromBrackets,
  showToast,
  sleepTime,
  formatDateMoment,
  isRegularUser,
  addUnixTimeToString,
  errorHandler,
  formatDateAsPerTimeZoneWithDateAndTime,
  isValidEmail,
  extractValidFields,
  generateUniqueFileNo,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { PostManager } from "../../../post-manager";
import { UploadQueueManager } from "../../../upload-queue-manager";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import FileNumberDuplicateModal from "../components/FileNumberDuplicateModal";
import { loanTypeConfig } from "../../../config";
import { RegexConfig } from "../../../config/RegexConfig";
import CreateClosingAddressComponent from "../components/common/createClosingAddressComponent";

const UpgradeAccountModal = lazy(() =>
  import("../components/Modals/upgradeAccountModal")
);
const FileChecked = lazy(() => import("../components/FileChecked"));
const ReportModal = lazy(() => import("../components/report-modal"));
const SignerInfoGroup = lazy(() => import("../components/SignerInfoGroup"));
// const ClosingAddress = lazy(() =>
//   import("../components/common/closingAddress")
// );
const SignerClosingInfo = lazy(() => import("../components/SignerClosingInfo"));
const Instructions = lazy(() => import("../components/instructions"));

class CreateClosing extends React.Component {
  constructor(props) {
    super(props);
    this.signerInfoGroupRef = React.createRef();
    this.closingAddressRef = React.createRef();
    this.borrowerAddressRef = React.createRef();
    this.addressRef = React.createRef();
  }

  state = {
    formFields: {
      loanOfficer: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      fileNumber: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      clientId: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      titleCompany: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      lender: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      personOfContactName: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      personOfContactEmail: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      ronPlatformType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanType: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: true,
      },
      loanTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      agentFee: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      appointmentDate: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      appointmentAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      closingAddressTimeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      agentNotes: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      instructions: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      isScanBackNeeded: {
        value: false,
        isDirty: false,
        isValidate: false,
      },
    },
    borrowerData: [],
    closingAddress: {},
    borrowerAddress: {},
    copyAddress: {},
    // closingGoogleAddress: "",
    // borrowerGoogleAddress: "",
    isBorrowerAddressSameAsAppointment: false,
    // isAppointmentAddressSame: false,
    uploadFiles: [],
    isAddressListShow: null, // appointmentAddress or borrowerAddress
    manuallyTypeAddress: {
      borrowerAddress: false,
      appointmentAddress: false,
    },
    confirmationFileLoading: false,
    inputLoanType: false,
    loanType: [],
    clients: [],
    addClientModalData: {
      isOpen: false,
      data: null,
    },
    reportModalData: {
      isOpen: false,
      data: null,
    },
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    fileChecked: {
      isOpen: false,
      data: null,
    },
    FileNumberUniqueChecked: {
      isOpen: false,
    },
    isOpenReactDatetime: false,
    aiParserResponse: {},
    ronPlatformTypeOptions: {},
    loading: {
      submitLoading: false,
    },
    existingFileNos: null,
    keepFileNo: false,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _resetState = () => {
    return new Promise((resolve) => {
      const defaultValue = {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      };

      const defaultField = {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      };
      this.setState(
        {
          formFields: {
            loanOfficer: {
              ...defaultValue,
            },
            fileNumber: {
              ...defaultField,
            },
            clientId: {
              ...defaultField,
            },
            titleCompany: {
              ...defaultField,
            },
            lender: {
              ...defaultField,
            },
            personOfContactName: {
              ...defaultField,
            },
            personOfContactEmail: {
              value: [],
              error: null,
              isDirty: false,
              isValidate: true,
            },
            signingType: {
              value: "Mobile",
              isDirty: false,
              isValidate: true,
            },
            ronPlatformType: {
              value: [],
              isDirty: false,
              isValidate: true,
            },
            witnessCount: {
              value: 0,
              isDirty: false,
              isValidate: true,
            },
            loanType: {
              value: [],
              error: null,
              isDirty: false,
              isValidate: true,
            },
            loanTypeOther: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            loanCategories: {
              value: [],
              error: null,
              isDirty: false,
              isValidate: false,
            },
            agentFee: {
              ...defaultField,
            },
            appointmentDate: {
              ...defaultField,
            },
            appointmentAddress: {
              value: "",
              placeId: null,
              error: null,
              isDirty: false,
              isValidate: true,
            },
            closingAddressTimeZone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            agentNotes: {
              ...defaultValue,
            },
            instructions: {
              ...defaultValue,
            },
            isScanBackNeeded: {
              value: false,
              isDirty: false,
              isValidate: false,
            },
          },
          isBorrowerAddressSameAsAppointment: false,
          // isAppointmentAddressSame: false,
          uploadFiles: [],
          isAddressListShow: null, // appointmentAddress or borrowerAddress
          manuallyTypeAddress: {
            borrowerAddress: false,
            appointmentAddress: false,
          },
          confirmationFileLoading: false,
          loading: {
            submitLoading: false,
          },
        },
        resolve()
      );
    });
  };

  componentDidMount = async () => {
    try {
      await this._getAgentClient(); //get list of clients
      this._getRonPlatformType(); //get Ron platform types

      if (this.props.location.search) {
        let id = this.props.location.search.replace(/[^\w\s]/gi, "");
        await this._getClosingDetailsById(id); // this function is executed when clone or changing from draft to normal closing
        console.log("call _setForm >>");
      }

      const res = await getRonPlatformTypes();
      console.log({ res });
      const ronOptions = res?.ronPlatformTypes
        ? Object.keys(res?.ronPlatformTypes)?.map((each) => ({
            label: each,
            value: res?.ronPlatformTypes[each],
          }))
        : [];
      console.log({ ronOptions });
      this.setState({
        ronPlatformTypeOptions: ronOptions,
      });
    } catch (error) {
      errorHandler(error);
    }
    HeaderEventEmitter.subscribe("back-button", () => {
      this._goBackBtn();
    });
    HeaderEventEmitter.subscribe("closingFile", (event) => {
      this._uploadConfirmationFile(event);
    });
  };

  _getRonPlatformType = async () => {
    try {
      const response = await getRonPlatformTypes();

      if (!response.error) {
        const convertedArray = Object.entries(response.ronPlatformTypes).map(
          ([label, value]) => ({ label, value })
        );
        this.setState({ ronPlatformOptions: convertedArray });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  _getClosingDetailsById = (id) => {
    return new Promise((resolve, reject) => {
      getClosingDetailsById(id)
        .then((res) => {
          this._setForm(res.closing);
          console.log("call _setForm");
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _getAgentClient = () => {
    return new Promise((resolve, reject) => {
      getAgentClient()
        .then((res) => {
          this.setState({ clients: res?.clients || [] });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
        });
    });
  };

  _setForm = (closingDetail) => {
    // function to be called on clone and changing draft into closing
    let {
      formFields,
      ronPlatformTypeOptions,
      closingAddress,
      borrowerAddress,
      // closingGoogleAddress,
      // borrowerGoogleAddress,
      clients,
    } = deepClone(this.state);
    let borrower = [];
    console.log("closingDetail 323>>", closingDetail);
    closingDetail?._borrower?.forEach((each) => {
      borrower.push({
        name: each.name.full || "",
        email: each.email || "",
        workPhone: getPhoneNumberFromBrackets(each?.phone?.work) || "",
        phoneHome: "",
        isForeignNational: each.isForeignNational,
        language: each.language,
        languageTypeOther: each.languageTypeOther || "",
        timeZone: each.timeZone || "",
      });
    });
    this.setState({ borrowerData: borrower });
    formFields["fileNumber"].value = closingDetail?.fileNumber;
    formFields["lender"].value = closingDetail?.lender || "";

    formFields["personOfContactName"].value =
      closingDetail?.personOfContact?.name || "";
    formFields["personOfContactEmail"].value =
      closingDetail?.personOfContact?.email[0]?.trim().length
        ? closingDetail?.personOfContact?.email
        : [];

    formFields["loanType"].value = closingDetail?.loanType?.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["loanCategories"].value = closingDetail?.loanCategories || [];

    formFields["ronPlatformType"].value = closingDetail?.ronPlatformTypes?.map(
      (each) => ({
        label: each,
        value: each,
      })
    );

    formFields["loanTypeOther"].value = closingDetail?.loanTypeOther || "";

    formFields["agentNotes"].value = closingDetail?.agentNotes || "";
    formFields["agentFee"].value = closingDetail?.agentFee?.toString() || "";
    formFields["instructions"].value = closingDetail?.instructions || "";
    formFields["signingType"].value = closingDetail?.signingType || "";
    formFields["closingAddressTimeZone"].value =
      closingDetail?.closingAddress?.timeZone || "";

    //add this condition sometimes client undefined
    if (clients !== undefined) {
      // console.log("closingDetail?._signingCompany?._id >>", clients);
      formFields["clientId"].value =
        // if client _client || _assistant || _signingCompany is not undefined
        closingDetail?._client ||
        closingDetail?._assistant ||
        closingDetail?._signingCompany
          ? clients?.find((item) => {
              return closingDetail?._client
                ? item?._client === closingDetail?._client?._id
                : closingDetail?._assistant
                ? item?._assistant === closingDetail?._assistant?._id
                : item?._signingCompany === closingDetail?._signingCompany?._id;
            })?._id
          : "";
    }

    // console.log("Client ID", formFields["clientId"].value);

    if (Object.keys(ronPlatformTypeOptions)?.length) {
      const temp = [];
      ronPlatformTypeOptions?.forEach((each) => {
        closingDetail?.ronPlatformTypes.forEach((subEach) => {
          if (subEach === each?.value) {
            temp.push(each);
          }
        });
      });

      // console.log({ temp });
      formFields["ronPlatformType"].value = temp || [];
    }

    if (closingDetail?.isImportedFile) {
      formFields["appointmentDate"].value =
        closingDetail?.appointmentDate || "";
    }

    borrowerAddress["addressLine1"] =
      closingDetail?._borrower[0]?.address?.line1 ||
      closingDetail?._borrower[0]?.address?.addressLine ||
      "";

    borrowerAddress["addressLine2"] =
      closingDetail?._borrower[0]?.address?.line2 || "";

    borrowerAddress["city"] = closingDetail?._borrower[0]?.address?.city || "";

    borrowerAddress["county"] =
      closingDetail?._borrower[0]?.address?.county || "";

    borrowerAddress["state"] =
      closingDetail?._borrower[0]?.address?.state || "";

    borrowerAddress["zip"] = closingDetail?._borrower[0]?.address?.zip || "";

    // borrowerGoogleAddress = Object.values({
    //   line1:
    //     closingDetail?._borrower[0]?.address?.line1 ||
    //     closingDetail?._borrower[0]?.address?.addressLine ||
    //     "",
    //   line2: closingDetail?._borrower[0]?.address?.line2 || "",
    //   city: closingDetail?._borrower[0]?.address?.city || "",
    //   state: closingDetail?._borrower[0]?.address?.state || "",
    //   zip: closingDetail?._borrower[0]?.address?.zip || "",
    // })
    //   .filter(Boolean)
    //   .join(",");

    closingAddress["addressLine1"] =
      closingDetail?.closingAddress?.line1 ||
      closingDetail?.closingAddress?.addressLine ||
      "";

    closingAddress["addressLine2"] = closingDetail?.closingAddress?.line2 || "";

    closingAddress["city"] = closingDetail?.closingAddress?.city || "";

    closingAddress["state"] = closingDetail?.closingAddress?.state || "";

    closingAddress["zip"] = closingDetail?.closingAddress?.zip || "";

    closingAddress["county"] = closingDetail?.closingAddress?.county || "";

    // closingGoogleAddress = Object.values({
    //   line1:
    //     closingDetail?.closingAddress?.line1 ||
    //     closingDetail?.closingAddress?.addressLine ||
    //     "",
    //   line2: closingDetail?.closingAddress?.line2 || "",
    //   city: closingDetail?.closingAddress?.city || "",
    //   state: closingDetail?.closingAddress?.state || "",
    //   zip: closingDetail?.closingAddress?.zip || "",
    // })
    //   .filter(Boolean)
    //   .join(",");

    if (closingDetail?.appointmentDate) {
      formFields["appointmentDate"].value =
        formatDateAsPerTimeZoneWithDateAndTime(
          closingDetail?.appointmentDate,
          closingDetail?.closingAddress?.timeZone
        );
    }

    let manuallyTypeAddress = {
      borrowerAddress: true,
      appointmentAddress: true,
    };

    this.setState(
      {
        formFields,
        borrowerAddress,
        closingAddress,
        manuallyTypeAddress,
        closingDetail,
        aiParserResponse: closingDetail,
        // borrowerGoogleAddress,
        // closingGoogleAddress,
      },
      () => {
        console.log("sauvik: ", this.state.formFields);
      }
    );
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            case "clientId": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressTimeZone": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "loanType":
            case "fileNumber":
            case "appointmentDate": {
              // console.log("sasasa ", formFields[key]);
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "loanTypeOther": {
              if (formFields.loanType.value === "Other") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "agentFee": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Fee must be a non-negative number";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "agentFee": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     if (
            //       isNaN(formFields[key].value) ||
            //       Number(formFields[key].value) <= 0
            //     ) {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Fee must be a positive number";
            //       isFormValid = false;
            //     } else {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     }
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = deepClone(this.state);

    if (fieldName === "agentFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "borrowerAddress" || fieldName === "appointmentAddress") {
      formFields[fieldName].placeId = null;
    }

    if (fieldName === "appointmentDate") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    } else {
      formFields[fieldName].value = value;
    }

    if (fieldName === "witnessCount") {
      let text =
        value !== ""
          ? !formFields.instructions.value.includes("witness")
            ? `<p>#${value} witness required.</p>`.concat(
                formFields.instructions.value
              )
            : formFields.instructions.value.replace(
                /\d+(?= witness required)/,
                value
              )
          : formFields.instructions.value.replace(/<p>#.*?<\/p>/, "");

      formFields["instructions"].value = text;
    }

    if (fieldName === "ronPlatformType") {
      let text = value?.length
        ? !formFields.instructions.value.includes("MUST BE A RON APPROVED")
          ? `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
              (each) => each?.value
            )}`.concat(formFields.instructions.value)
          : formFields.instructions.value.replace(
              /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
              `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
                (each) => each?.value
              )}`
            )
        : formFields.instructions.value.replace(
            /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
            ""
          );

      // console.log({ text });
      formFields["instructions"].value = text;
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      // console.log(this.state.formFields);
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _createAgentClosing = (data) => {
    // create Closing api call
    // console.log("api call data: ", data);
    createAgentClosing(data)
      .then((res) => {
        showToast("Closing Created Successfully", "success");
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
        this.props.history.push("/agent/dashboard");
      })
      .catch((error) => {
        console.log(error);
        errorHandler(error);
        this._manageLoading("submitLoading", false);
        this.props.hideLoader();
      });
  };

  _updateAgentClosingById = (data) => {
    // update closing api call for changing draft into closing
    updateAgentClosingById(this.state.closingDetail.id, data)
      .then((res) => {
        showToast("Closing Created Successfully", "success");
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
        this.props.history.push("/agent/dashboard");
      })
      .catch((error) => {
        console.log(error);
        errorHandler(error);
        this._manageLoading("submitLoading", false);
        this.props.hideLoader();
      });
  };

  _structureData = (updatedFormfields) => {
    console.log("updatedFormfields", updatedFormfields);
    const data = updatedFormfields?.map((each) => {
      let obj = {
        email: each?.email?.value,
        phone: {
          home: each?.phoneHome?.value || undefined,
          work: each?.workPhone?.value || undefined,
        },
        name: {
          first: each?.name?.value?.split(" ").slice(0, -1).join(" "),
          last: each?.name?.value?.split(" ").slice(-1).join(" "),
        },
        isForeignNational: each?.isForeignNational?.value,
        language: each?.language?.value,
        languageTypeOther:
          each?.language?.value === "Other"
            ? each?.languageTypeOther?.value
            : undefined,
        address: {},
        timeZone: each?.timeZone?.value || undefined,
      };
      return obj;
    });
    return data;
  };

  _createPayload = async (borrowerData) => {
    const { formFields, clients } = deepClone(this.state);

    let borrower;

    // If this function call from create report modal
    if (borrowerData === undefined) {
      let res = await this.signerInfoGroupRef?.current?._handleSubmit();
      borrower = this._structureData(res?.updatedFormfields);
    } else {
      borrower = this._structureData(borrowerData);
    }

    // If this function call from create report modal
    const addressResponse = await this.addressRef?.current?._handleSubmit();
    const { closingAddressResponse, borrowerAddressResponse } = addressResponse;

    const payload = {
      agentFee: formFields.agentFee.value.trim(),
      fileNumber: formFields.fileNumber.value.trim(),
      lender: formFields.lender.value,

      personOfContact: {
        name: formFields.personOfContactName.value.trim(),
        email: formFields.personOfContactEmail.value,
      },

      loanTypeOther:
        formFields.loanType.value.filter((item) => item.value === "Other")
          .length > 0
          ? formFields.loanTypeOther.value
          : undefined,
      appointmentDate: formatDateMoment(formFields.appointmentDate.value),
      closingAddress: {},
      isScanBackNeeded: formFields.isScanBackNeeded.value,
    };

    payload["borrower"] = borrower;

    if (formFields.loanType.value) {
      payload["loanType"] = await formFields.loanType.value.map(
        (item) => item.value
      );
    }
    if (formFields.loanCategories.value) {
      payload["loanCategories"] = await formFields?.loanCategories?.value;
    }

    if (formFields?.closingAddressTimeZone.value?.length) {
      payload["timeZone"] = formFields?.closingAddressTimeZone.value
        ? formFields?.closingAddressTimeZone?.value
        : undefined;
    }
    // Same address

    payload["closingAddress"] = {
      line1: closingAddressResponse?.addressLine1?.value?.trim().length
        ? closingAddressResponse?.addressLine1?.value
        : undefined,
      line2: closingAddressResponse?.addressLine2?.value?.trim().length
        ? closingAddressResponse?.addressLine2?.value
        : undefined,
      city: closingAddressResponse?.city?.value?.trim().length
        ? closingAddressResponse?.city?.value
        : undefined,
      state: closingAddressResponse?.state?.value?.trim().length
        ? closingAddressResponse?.state?.value
        : undefined,
      zip: closingAddressResponse?.zip?.value?.trim().length
        ? closingAddressResponse?.zip?.value
        : undefined,
      timeZone: formFields?.closingAddressTimeZone.value
        ? formFields?.closingAddressTimeZone?.value
        : undefined,
      county: closingAddressResponse?.county?.value?.trim().length
        ? closingAddressResponse?.county?.value
        : undefined,
    };

    const address =
      borrowerAddressResponse && Object.values(borrowerAddressResponse)?.length
        ? extractValidFields(borrowerAddressResponse)
        : {};

    const modifiedAddress = {
      ...address,
      line1: address.addressLine1,
      line2: address.addressLine2,
    };

    delete modifiedAddress.addressLine1;
    delete modifiedAddress.addressLine2;

    const newArray = borrower.map((obj) => ({
      ...obj,
      address: modifiedAddress,
    }));
    payload["borrower"] = newArray;
    // console.log("payload >>>>>", payload);
    // return;

    if (formFields.agentNotes.value?.trim().length)
      payload["agentNotes"] = formFields.agentNotes.value.trim();

    if (formFields.instructions.value?.trim().length)
      payload["instructions"] = formFields.instructions.value.trim();

    if (formFields.loanOfficer.value?.trim().length)
      payload["loanOfficer"] = formFields.loanOfficer.value.trim();

    if (
      formFields.signingType.value === "RON" ||
      (formFields?.loanType?.value?.length === 1 &&
        formFields?.loanType?.value[0]?.value === "AttorneyPhone")
    )
      payload.closingAddress["timeZone"] =
        formFields.closingAddressTimeZone.value;

    if (formFields.signingType.value === "RON")
      payload.ronPlatformTypes = formFields?.ronPlatformType?.value?.length
        ? formFields?.ronPlatformType?.value?.map((each) => each?.value)
        : undefined;

    if (
      formFields.signingType.value &&
      formFields.signingType.value.trim().length
    ) {
      payload["signingType"] = formFields.signingType.value.trim();
    }
    if (
      formFields.witnessCount.value &&
      formFields.witnessCount.value.trim().length
    ) {
      payload["witnessCount"] = Number(formFields.witnessCount.value.trim());
    }
    if (formFields.clientId.value && formFields.clientId.value.trim().length) {
      // eslint-disable-next-line array-callback-return
      clients.find((each) => {
        if (each._id === formFields.clientId.value) {
          console.log("each >>", each);
          if (each.hasOwnProperty("_client") && each._client !== "") {
            payload["clientId"] = each._client;
          } else if (
            each.hasOwnProperty("_signingCompany") &&
            each._signingCompany !== ""
          ) {
            payload["signingCompanyId"] = each._signingCompany;
          } else {
            payload["assistantId"] = each._assistant;
          }
        }
      });
    }
    if (formFields.ronPlatformType.value) {
      payload["ronPlatformTypes"] = await formFields.ronPlatformType.value.map(
        (item) => item.value
      );
    }

    if (
      this.state.closingDetail?.isImportedFile &&
      this.state.closingDetail?.isCreatedForAgent &&
      this.state.closingDetail?.isSavedAsDraft
    ) {
      payload["isSavedAsDraft"] = false;
    }

    return payload;
  };

  _submitCreateAgentClosing = async (e) => {
    this._manageLoading("submitLoading", true);
    const { closingDetail } = this.state;

    if (e) e.preventDefault();

    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    const res = await this.signerInfoGroupRef?.current?._handleSubmit();

    const addressResponse = await this.addressRef?.current?._handleSubmit();

    const { isClosingAddressValid } = addressResponse;

    if (!isFormValid || !res?.isFormValid || !isClosingAddressValid) {
      Object.keys(this.state.formFields).forEach((key) => {
        if (this.state.formFields[key].error) {
          const result = key.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          const finalResult2 =
            finalResult === "Loan Type" ? "Product Type" : finalResult;
          showToast(`Please Fill The ${finalResult2} Fields`, "error");
        }
      });
      if (!this.state.formFields.fileNumber.value.trim().length > 0) {
        this._toggleFileCheckedModal(true);
      }

      if (!isClosingAddressValid) {
        errorHandler({ reason: "Please provide valid Closing Address" });
      }

      this._manageLoading("submitLoading", false);
    }

    if (isFormValid && res?.isFormValid && isClosingAddressValid) {
      this.props.showLoader("Create Closing...");

      const { uploadFiles } = deepClone(this.state);

      const payload = await this._createPayload(res?.updatedFormfields);
      console.log({ payload });

      if (uploadFiles && uploadFiles.length) {
        payload["documents"] = [];

        let postID = PostManager.addMediaFilesCount(uploadFiles.length);

        PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
          if (id.postID === postID) {
            await sleepTime(500);
            this._createAgentClosing(payload);
            PostManager.deletePostID(postID);
          } else {
            return;
          }
        });

        uploadFiles.forEach((uploadFile) => {
          // for logo images
          let mediaData = {
            file: uploadFile.uploadData,
            blobObject: uploadFile.previewBlob,
          };
          const uploadId = UploadQueueManager.addMediaToQueue(mediaData, "pdf");

          // Listen for upload complete
          UploadQueueManager.onUploadComplete(
            uploadId,
            async (mediaResponse) => {
              PostManager.onSingleMediaFileUploadCompleted(postID);

              // Upload complete
              // Get content id from backend
              payload.documents.push({
                title: addUnixTimeToString(uploadFile.uploadData.name),
                url: mediaResponse.fileUrl,
                docType: getFileExtension(uploadFile.uploadData.name),
                category: "confirmation",
              });
            }
          );
        });
      } else {
        if (closingDetail?.isSavedAsDraft) {
          this._updateAgentClosingById(payload);
        } else {
          this._createAgentClosing(payload);
        }
      }
    }
  };

  _goBackBtn = () => {
    this.props.history.push("/agent/dashboard");
  };

  _toggleManualOrGoogleAddress = (fieldName) => {
    const { manuallyTypeAddress } = this.state;
    manuallyTypeAddress[fieldName] = !manuallyTypeAddress[fieldName];
    this.setState({ manuallyTypeAddress });
  };

  checkNotMetioned = (text) => {
    let toAvoid = [
      "Not mentioned",
      "N/A (not specified)",
      "Not Specified",
      "Not mentioned in the text",
      "N/A (not mentioned in the text)",
      "None provided in text",
    ];
    let lowerCaseText = text.toLowerCase();

    for (let phrase of toAvoid) {
      if (lowerCaseText.includes(phrase.toLowerCase())) {
        return false;
      }
    }

    return true;
  };

  _filterValidEmail = (string) => {
    const emailPattern = /[^\s@]+@[^\s@]+\.[^\s@]+/g;
    const emails = string.match(emailPattern) || [];
    return emails.filter((email) =>
      RegexConfig.email.test(String(email).toLowerCase())
    );
  };

  _removeKeyValuePair(obj) {
    return new Promise((resolve, reject) => {
      try {
        const newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            const updatedBorrowers = value.map((borrower) => {
              const updatedBorrower = {};
              Object.entries(borrower).forEach(
                ([borrowerKey, borrowerValue]) => {
                  if (
                    !(
                      borrowerValue.toLowerCase().includes("not ") ||
                      borrowerValue.toLowerCase().includes("n/a") ||
                      borrowerValue.toLowerCase().includes("none")
                    )
                  ) {
                    updatedBorrower[borrowerKey] = borrowerValue;
                  }
                }
              );
              return updatedBorrower;
            });
            newObj[key] = updatedBorrowers;
          } else if (
            // typeof value === "string" &&
            // !(
            //   value.toLowerCase().includes("not ") ||
            //   value.toLowerCase().includes("n/a") ||
            //   value.toLowerCase().includes("none")
            // )
            !(
              typeof value === "string" &&
              (value.toLowerCase().includes("not ") ||
                value.toLowerCase().includes("n/a") ||
                value.toLowerCase().includes("none"))
            )
          ) {
            newObj[key] = value;
          }
        });
        resolve(newObj);
      } catch (error) {
        reject(error);
      }
    });
  }

  _setformFieldData = async (response, objFile) => {
    try {
      await this._resetState();
      let data = await this._removeKeyValuePair(response);
      // console.log("res", data);

      let {
        formFields,
        closingAddress,
        borrowerAddress,
        // closingGoogleAddress,
        // borrowerGoogleAddress,
      } = deepClone(this.state);

      console.log("data?.borrowers >>", data);
      let borrower = [];
      data?.borrowers?.forEach((each) => {
        borrower.push({
          name: each.borrowerName || "",
          email: each.borrowerEmail || "",
          workPhone: each?.borrowerWorkPhone
            ? getPhoneNumberFromBrackets(each?.borrowerWorkPhone)
            : each?.borrowerHomePhone
            ? getPhoneNumberFromBrackets(each?.borrowerHomePhone)
            : each?.borrowerCellPhone
            ? getPhoneNumberFromBrackets(each?.borrowerCellPhone)
            : "",
          phoneHome: "",
          isForeignNational: false,
          language: "",
          languageTypeOther: "",
          timeZone: "",
        });
      });
      this.setState({ borrowerData: borrower });
      formFields["personOfContactName"].value = data?.pocName || "";
      formFields["personOfContactEmail"].value =
        data?.pocEmail?.split(" ") || [];

      formFields["fileNumber"].value = data?.fileNumber || "";
      // formFields["lender"].value = data?.lender || "";
      formFields["lender"].value =
        // && this.checkNotMetioned(data?.lender)
        data?.lender ? data.lender : "";

      if (data?.appointmentDateTime) {
        if (data?.appointmentDateTime.includes("EST")) {
          formFields["appointmentDate"].value = data?.appointmentDateTime.slice(
            0,
            data?.appointmentDateTime.indexOf("E") - 1
          );
        } else {
          formFields["appointmentDate"].value = data?.appointmentDateTime;
        }
      }

      formFields["signingType"].value =
        data?.signingType &&
        (data?.signingType === "Mobile" || data?.signingType === "RON")
          ? data?.signingType
          : "Mobile";

      formFields["loanType"].value =
        Object.values(loanTypeConfig)
          .flat()
          .filter((item) => item === data?.closingType).length === 0
          ? [{ label: "Other", value: "Other" }]
          : [{ label: data?.closingType, value: data?.closingType }];
      // formFields["loanTypeOther"].value = data?.closingType || "";
      formFields["loanTypeOther"].value =
        // && this.checkNotMetioned(data?.closingType)
        data?.closingType ? data?.closingType : "";

      formFields["agentFee"].value = data?.agentFee
        ? data?.agentFee?.replace(/[^\d.]/g, "")
        : // ? data?.agentFee.replace(/[$]/g, "")
          "";

      borrowerAddress["addressLine1"] =
        data?.propertyAddress?.addressLine ||
        data?.propertyAddress?.line1 ||
        "";
      borrowerAddress["addressLine2"] = data?.propertyAddress?.line2 || "";
      borrowerAddress["city"] = data?.propertyAddress?.city || "";
      borrowerAddress["state"] = data?.propertyAddress?.state || "";
      borrowerAddress["zip"] = data?.propertyAddress?.zip || "";

      // borrowerGoogleAddress = Object.values({
      //   line1:
      //     data?.propertyAddress?.line1 ||
      //     data?.propertyAddress?.addressLine ||
      //     "",
      //   line2: data?.propertyAddress.line2 || "",
      //   city: data?.propertyAddress.city || "",
      //   state: data?.propertyAddress.state || "",
      //   zip: data?.propertyAddress.zip || "",
      // })
      //   .filter(Boolean)
      //   .join(",");

      closingAddress["addressLine1"] =
        data?.closingAddress?.addressLine || data?.closingAddress?.line1 || "";
      closingAddress["addressLine2"] = data?.closingAddress?.line2 || "";
      closingAddress["city"] = data?.closingAddress?.city || "";
      closingAddress["state"] = data?.closingAddress?.state || "";
      closingAddress["zip"] = data?.closingAddress?.zip || "";

      // closingGoogleAddress = Object.values({
      //   line1:
      //     data?.closingAddress?.line1 ||
      //     data?.closingAddress?.addressLine ||
      //     "",
      //   line2: data?.closingAddress.line2 || "",
      //   city: data?.closingAddress.city || "",
      //   state: data?.closingAddress.state || "",
      //   zip: data?.closingAddress.zip || "",
      // })
      //   .filter(Boolean)
      //   .join(",");

      let uploadFiles = [
        {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          isConfirmationFile: true,
        },
      ];

      let manuallyTypeAddress = {
        borrowerAddress: true,
        appointmentAddress: true,
      };

      this.setState({
        formFields,
        closingAddress,
        borrowerAddress,
        uploadFiles,
        manuallyTypeAddress,
        aiParserResponse: data,
        // borrowerGoogleAddress,
        // closingGoogleAddress,
      });
      showToast("File uploaded", "success");
    } catch (error) {
      console.log("error >>", error);
      showToast("Something went wrong. Try again after sometime.", "error");
    }
  };

  _uploadConfirmationFile = async (event) => {
    try {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length
      ) {
        let objFile = event.target.files[0];
        if (objFile.type.includes("pdf")) {
          this.setState({ confirmationFileLoading: true });
          HeaderEventEmitter.dispatch("confirmationFileLoading", true);

          const confirmationFile = new FormData();
          confirmationFile.append("file", objFile);

          const response = await uploadClosingPdf(confirmationFile);

          // if (response.data?.borrowers?.length) {
          isRegularUser() && this._setformFieldData(response?.data, objFile);
          // } else {
          //   showToast("Invalid File", "error");
          // }

          this.setState({ confirmationFileLoading: false });
          HeaderEventEmitter.dispatch("confirmationFileLoading", false);
        } else {
          this.setState({ confirmationFileLoading: false });
          showToast("Only PDF file is allowed", "error");
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  _toggleAddClientModal = (isOpen = false, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
      },
    });
  };

  _toggleReportModal = (isOpen = false) => {
    const { aiParserResponse } = deepClone(this.state);
    this.setState({
      reportModalData: {
        isOpen,
        data: {
          aiParserResponse: aiParserResponse,
        },
      },
    });
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileCheckedModal = (isOpen = false, data = null) => {
    this.setState({
      fileChecked: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileNumberUniqueCheckedModal = (isOpen = false) => {
    this.setState({
      FileNumberUniqueChecked: {
        isOpen,
      },
    });
  };

  _approvedFileNumber = (value) => {
    const { formFields } = deepClone(this.state);
    formFields["fileNumber"].value = "CW-" + value?.FileNumber;
    this.setState({ formFields }, () => {
      this._toggleFileCheckedModal();
      this._validateForm();
    });
  };

  _onChipSelect = (chips, arrName = "chips") => {
    let isValid = chips.length ? isValidEmail(chips[chips.length - 1]) : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          // chips: chips,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _sortText = (input) => {
    if (!input) {
      return "";
    }

    const signerRegex = /<p>.*?\bSigner\s+\d+\b.*?<\/p>/g;
    const signerMatches = input.match(signerRegex);

    // sort the signer strings in ascending order
    signerMatches.sort();

    // replace the original signer strings with the sorted ones
    let output = input.replace(signerRegex, () => signerMatches.shift());

    return output;
  };

  _onChangeFormFieldBorrower = (fieldName, value, index) => {
    const { formFields } = deepClone(this.state);

    if (fieldName === "isForeignNational") {
      let text = value
        ? `<p>Signer ${index + 1} is a foreign National.</p>`.concat(
            formFields.instructions.value
          )
        : formFields.instructions.value.replace(
            `<p>Signer ${index + 1} is a foreign National.</p>`,
            ""
          );

      formFields["instructions"].value = this._sortText(text);
    }
    if (fieldName === "timeZone") {
      let text =
        value !== ""
          ? !formFields.instructions.value.match(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} is in ${value}.</p>`.concat(
                formFields.instructions.value
              )
            : formFields.instructions.value.replace(
                new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
                `<p>Signer ${index + 1} is in ${value}.</p>`
              )
          : formFields.instructions.value.replace(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
              ""
            );

      formFields["instructions"].value = this._sortText(text);
    }
    if (fieldName === "language") {
      let text =
        value !== ""
          ? !formFields.instructions.value.match(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} speaks ${value}.</p>`.concat(
                formFields.instructions.value
              )
            : formFields.instructions.value.replace(
                new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
                `<p>Signer ${index + 1} speaks ${value}.</p>`
              )
          : formFields.instructions.value.replace(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
              ""
            );
      formFields["instructions"].value = this._sortText(text);
    }
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  _duplicateFileNumberCheck = async (e) => {
    if (e) e.preventDefault();
    let { fileNumber } = deepClone(this.state.formFields);
    const { formFields } = deepClone(this.state);
    try {
      this._manageLoading("submitLoading", true);
      const res = await FileNumberDuplicateMatchCheck({
        fileNumber: fileNumber.value,
      });

      if (res?.existingFileNos.length > 0) {
        // this._toggleFileNumberUniqueCheckedModal(true);
        this.setState({ existingFileNos: res.existingFileNos });
        const uniqueFileNo = generateUniqueFileNo(
          res.existingFileNos,
          fileNumber.value
        );
        this._onChangeFormField("fileNumber", uniqueFileNo);
        formFields.fileNumber.value = uniqueFileNo;
        this.setState({ formFields }, async () => {
          await this._submitCreateAgentClosing(e);
        });
      } else {
        await this._submitCreateAgentClosing(e);
      }
      this._manageLoading("submitLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("submitLoading", false);
    }
  };

  _resetAddress = (fieldName, addressData) => {
    this.setState({ [`${fieldName}`]: addressData });
  };

  render() {
    const {
      formFields,
      // borrowerAddress,
      // closingAddress,
      // isBorrowerAddressSameAsAppointment,
      // isAppointmentAddressSame,
      confirmationFileLoading,
      upgradeAccountModal,
      fileChecked,
      clients,
      uploadFiles,
      reportModalData,
      closingDetail,
      aiParserResponse,
      ronPlatformTypeOptions,
      FileNumberUniqueChecked,
      loading,
      existingFileNos,
    } = this.state;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={() =>
                  !isRegularUser() && this._toggleUpgradeAccountModal(true)
                }
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                  onClick={() => this._goBackBtn()}
                />
              </Button>
              <h2>Create Order</h2>
            </div>

            <div className="rightSide">
              <Label
                className="uploadBtn floatingButton"
                for="uploadAutomation"
                onClick={() =>
                  !isRegularUser() && this._toggleUpgradeAccountModal(true)
                }
              >
                {confirmationFileLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <>
                    <img
                      src={
                        require("../../../assets/img/uploadIcon.svg").default
                      }
                      alt="upload automation"
                    />{" "}
                    UPLOAD
                  </>
                )}
                {isRegularUser() && (
                  <Input
                    type="file"
                    id="uploadAutomation"
                    accept=".pdf"
                    disabled={confirmationFileLoading}
                    title="Upload Confirmation"
                    value=""
                    onChange={(event) => this._uploadConfirmationFile(event)}
                  />
                )}
              </Label>
            </div>
          </div>
          <Row>
            <Col>
              <Suspense fallback={<></>}>
                <Card>
                  <CardHeader>
                    <CardTitle>Order Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <SignerClosingInfo
                      type="agent"
                      lenderClients={clients}
                      formFields={formFields}
                      // loanType={loanType}
                      ronPlatformTypeOptions={ronPlatformTypeOptions}
                      onChangeFormField={(fieldName, value) =>
                        this._onChangeFormField(fieldName, value)
                      }
                      onChipSelect={(arr, fieldName) =>
                        this._onChipSelect(arr, fieldName)
                      }
                      onSucessFullyClientAdd={this._getAgentClient}
                      xl={4}
                      lg={6}
                      md={6}
                      xm={12}
                    />
                  </CardBody>
                </Card>
              </Suspense>
              <Card>
                <CardHeader>
                  <CardTitle>Appointment Detail</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="ClosingWrapper">
                    <Col xl={4} md={6}>
                      <FormGroup
                        className={`floatingLabel mb-0 ${
                          formFields.appointmentDate.value ||
                          this.state.isOpenReactDatetime
                            ? "valueAdded"
                            : ""
                        }`}
                      >
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: " ",
                            value: formatDateAndTime(
                              formFields.appointmentDate.value
                            ),
                          }}
                          onChange={(e) =>
                            this._onChangeFormField("appointmentDate", e._d)
                          }
                          onOpen={() =>
                            this.setState({ isOpenReactDatetime: true })
                          }
                          onClose={() =>
                            this.setState({ isOpenReactDatetime: false })
                          }
                          value={
                            formFields.appointmentDate.value
                              ? new Date(formFields.appointmentDate.value)
                              : ""
                          }
                          timeFormat={true}
                          timeConstraints={{ minutes: { step: 15 } }}
                        />
                        {formFields.appointmentDate.error && (
                          <div className="validation-error">
                            {formFields.appointmentDate.error}
                          </div>
                        )}
                        <Label>Appointment Date & Time</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Suspense fallback={<></>}>
                <Card>
                  <CardHeader>
                    <CardTitle>Signer Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <SignerInfoGroup
                      ref={this.signerInfoGroupRef}
                      _onChangeFormFieldBorrower={(fieldName, value, index) =>
                        this._onChangeFormFieldBorrower(fieldName, value, index)
                      }
                      data={this.state.borrowerData}
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      isNeedToShownTimeZone={
                        formFields?.signingType?.value === "RON" ||
                        (formFields?.loanType?.value?.length === 1 &&
                          formFields?.loanType?.value[0]?.value ===
                            "AttorneyPhone")
                      }
                      className="multipleRow"
                    />
                  </CardBody>
                </Card>
              </Suspense>

              <CreateClosingAddressComponent
                xl={4}
                lg={6}
                md={6}
                xm={12}
                ref={this.addressRef}
                borrowerAddress={this.state.borrowerAddress}
                closingAddress={this.state.closingAddress}
                // closingGoogleAddress={this.state.closingGoogleAddress}
                // borrowerGoogleAddress={this.state.borrowerGoogleAddress}
                isClosingAddressRequired={
                  formFields.signingType.value !== "RON" &&
                  !(
                    formFields?.loanType?.value?.length === 1 &&
                    formFields?.loanType?.value[0]?.value === "AttorneyPhone"
                  )
                }
              />

              <Suspense fallback={<></>}>
                <Card>
                  <CardHeader>
                    <CardTitle>Signing Instruction</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Instructions
                      instructionData={formFields.instructions.value}
                      onChangeFormField={this._onChangeFormField}
                      hideInstructionId={true}
                      isScanBackNeededData={formFields.isScanBackNeeded.value}
                      hideScanback={true}
                    />
                  </CardBody>
                </Card>
              </Suspense>
              <Suspense fallback={<></>}>
                <Card>
                  <CardHeader>
                    <CardTitle>Additional Instruction</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      type="checkbox"
                      id="isScanBackNeeded"
                      label="Scan Backs Needed"
                      className="mb-2"
                      checked={formFields.isScanBackNeeded.value}
                      onChange={(event) =>
                        this._onChangeFormField(
                          "isScanBackNeeded",
                          event.target.checked
                        )
                      }
                    />
                  </CardBody>
                </Card>
              </Suspense>

              {/* {Object.keys(aiParserResponse)?.length ? ( */}
              <p className="bold">
                Not satisfied with the results of our automated system?
                {/* {Report} */}
                <span
                  onClick={() => {
                    if (!Object.keys(aiParserResponse)?.length) {
                      errorHandler("Pls upload a file first");
                      return;
                    }

                    this._toggleReportModal(true);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    marginLeft: "10px",
                    textDecoration: "underline",
                  }}
                >
                  Report
                </span>
              </p>
              {/* ) : null} */}

              <Button
                color="primary"
                className="mx-auto d-block mt-4"
                size="lg"
                onClick={
                  (e) =>
                    this.state.formFields?.fileNumber?.value?.length
                      ? this._duplicateFileNumberCheck(e)
                      : this._submitCreateAgentClosing(e)
                  // !keepFileNo
                  //   ? this._duplicateFileNumberCheck(e)
                  //   : this._submitCreateAgentClosing(e)
                }
                disabled={loading?.submitLoading}
              >
                {loading?.submitLoading ? (
                  <i className="fa fa-spinner fa-spin mr-2" />
                ) : null}{" "}
                Create
              </Button>
            </Col>
            {uploadFiles.length > 0 && (
              <Col xl={4} lg={4}>
                <div className="pdfView">
                  {/* <iframe
                    title="pdf"
                    src={uploadFiles[0]?.previewBlob}
                  ></iframe> */}
                  <object
                    data={uploadFiles[0]?.previewBlob}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  >
                    <p>
                      Your browser does not support PDFs.{" "}
                      <a href={uploadFiles[0]?.previewBlob}>Download the PDF</a>
                      .
                    </p>
                  </object>
                </div>
              </Col>
            )}
            {closingDetail?.importedEmailBody &&
              !closingDetail?.documents?.length > 0 && (
                <Col xl={3} lg={4}>
                  <div className="pdfContent">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: closingDetail?.importedEmailBody,
                      }}
                    />
                  </div>
                </Col>
              )}
            {closingDetail?.documents?.length > 0 ? (
              <Col xl={3} lg={4}>
                <embed
                  src={`https://docs.google.com/gview?url=${encodeURIComponent(
                    closingDetail?.documents[0]?.url
                  )}&embedded=true`}
                  type="application/pdf"
                  width="100%"
                  height="800"
                />
              </Col>
            ) : null}
          </Row>
        </div>

        {/* Report AI response */}
        <Suspense fallback={<></>}>
          <ReportModal
            isOpen={reportModalData.isOpen}
            data={reportModalData.data}
            uploadFiles={uploadFiles}
            getParentpayload={this._createPayload}
            toggle={this._toggleReportModal}
          />
        </Suspense>

        <Suspense fallback={<></>}>
          <UpgradeAccountModal
            {...this.props}
            isOpen={upgradeAccountModal.isOpen}
            toggle={this._toggleUpgradeAccountModal}
          />
        </Suspense>

        <Suspense fallback={<></>}>
          <FileChecked
            isOpen={fileChecked.isOpen}
            approved={this._approvedFileNumber}
            toggle={() => this._toggleFileCheckedModal()}
          />
        </Suspense>

        {/* {FileNumberUniqueChecked?.isOpen ? (
          <FileNumberDuplicateModal
            isOpen={FileNumberUniqueChecked.isOpen}
            toggle={() => this._toggleFileNumberUniqueCheckedModal()}
            FileNumberSubmitHandler={(e) => this._submitCreateAgentClosing(e)}
          />
        ) : null} */}
        {console.log("FileNumberUniqueChecked", FileNumberUniqueChecked)}
        {FileNumberUniqueChecked?.isOpen ? (
          <FileNumberDuplicateModal
            isOpen={FileNumberUniqueChecked.isOpen}
            fileNumber={formFields.fileNumber.value}
            toggle={() => this._toggleFileNumberUniqueCheckedModal()}
            existingFileNos={existingFileNos}
            keep={(e) =>
              this.setState({ keepFileNo: true }, () => {
                this._toggleFileNumberUniqueCheckedModal();
                this._submitCreateAgentClosing(e);
              })
            }
            FileNumberSubmitHandler={(value) =>
              this._onChangeFormField("fileNumber", value)
            }
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClosing);
