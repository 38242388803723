import React, { Suspense } from "react";
import {
  capitalize,
  checkPermission,
  formatAddressInSingleText,
  formatPhoneNumber,
  getDropdownColor,
} from "../../../helper-methods";
import { Button, Input } from "reactstrap";
import SignerPopover from "./common/popover";

const ClientAgentDataFormat = ({
  header,
  row,
  cell,
  loading,
  _clientStatusUpdate,
  _toggleAddClientModal,
  _handleOnDeleteClientAlert,
}) => {
  switch (header) {
    case "name": {
      return (
        <div className="tableUserInfo">
          <div className="userImage">
            <img
              className="clientImg"
              src={
                row.logo
                  ? row.logo
                  : require("../../../assets/img/default_user_icon.svg").default
              }
              loading="lazy"
              alt="avatar"
            />
          </div>
          <div className="userContent">
            <span>
              {capitalize(
                row?._signingCompany
                  ? row.companyName
                  : row?._client
                  ? row.name?.full
                  : row?._assistant
                  ? row.name?.full
                  : "N/A"
              )}
            </span>
            <div style={{ width: 90 }} className="mt-1">
              {row?.clientId ? `(${row?.clientId})` : ""}
            </div>
          </div>
        </div>
      );
    }
    case "email": {
      return (
        <>
          <div style={{ width: 90 }}>{cell ? cell : "N/A"}</div>
        </>
      );
    }
    case "clientId": {
      return (
        <>
          <div style={{ width: 90 }}>{cell ? cell : "N/A"}</div>
        </>
      );
    }
    case "phone": {
      return (
        <>
          <div style={{ width: 90 }}>
            {cell ? formatPhoneNumber(cell) : "N/A"}
          </div>
        </>
      );
    }
    case "address": {
      return (
        <>
          <div style={{ width: 90 }}>
            {cell ? <>{formatAddressInSingleText(cell)}</> : "N/A"}
          </div>
        </>
      );
    }
    case "tags": {
      return (
        <>
          {row?.noOftags || row?.noOftags === 0 ? (
            <>
              <Button
                color="link"
                id={`agent_client_tags${row?._id}`}
                type="button"
              >
                {row?.noOftags}
              </Button>

              {row?.tags?.length > 0 ? (
                <Suspense fallback={<></>}>
                  <SignerPopover
                    data={row?.tags}
                    targetId={row?._id}
                    displayType="tags"
                  />
                </Suspense>
              ) : null}
            </>
          ) : (
            "N/A"
          )}
        </>
      );
    }
    case "pointOfContact": {
      return cell ? cell : "N/A";
    }
    case "isActive": {
      return row ? (
        <>
          <div className="customSelectWrap" style={{ width: 90 }}>
            <Input
              type="select"
              className={`status ${getDropdownColor(
                row.isActive ? "active" : "inactive"
              )}`}
              name="status"
              disabled={
                loading?.statusChangeLoading ||
                !checkPermission("clients", "canUpdate")
              }
              value={row.isActive ? "active" : "inactive"}
              onChange={(event) => _clientStatusUpdate(row, event.target.value)}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
            <div className="downArrow">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        </>
      ) : (
        "N/A"
      );
    }
    case "action": {
      return row ? (
        <div style={{ width: 80 }}>
          <Button
            title="Edit"
            color="link"
            className="actionBtn"
            onClick={() => _toggleAddClientModal(true, row)}
            disabled={
              !checkPermission("clients", "canUpdate") || row?._signingCompany
            }
          >
            <img
              src={require("../../../assets/img/pencil.svg").default}
              alt="edit"
            />
          </Button>
          <Button
            color="link"
            onClick={() => _handleOnDeleteClientAlert(row?._client)}
            disabled={row?._signingCompany || loading?.clientDeleteLoading}
          >
            <img
              src={require("../../../assets/img/deleteIcon.svg").default}
              alt="delete"
            />
          </Button>
        </div>
      ) : (
        "N/A"
      );
    }
    default: {
      return cell;
    }
  }
};

export default ClientAgentDataFormat;
