import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  errorHandler,
  isRegularUser,
  showToast,
} from "../../../helper-methods";
// import { initializeClient } from "../../../helper-methods/googleCalendarService";
import {
  userGoogleCalendarSync,
  removeGoogleCalendarSync,
} from "../../../http/http-calls";
import { APP_URL } from "../../../config";
import UpgradeAccountModal from "./Modals/upgradeAccountModal";
import { updateUserObj } from "../../../redux/actions/user-data";
import SvgIcons from "./SvgIcons";

const GoogleCalendarSyncModal = ({ fetchData }) => {
  const [loading, setLoading] = useState(false);
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);
  const userData = useSelector((state) => state.userData);

  const dispatch = useDispatch();

  const isGoogleCalendarSignInDone = userData?.user?.isGoogleCalendarSignInDone;

  const _userGoogleCalendarSync = async () => {
    console.log("_userGoogleCalendarSync Call");
    try {
      await userGoogleCalendarSync();
      showToast("Google Calendar synced Successfully", "success");
      fetchData();
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _handleGoogleCalendarAuthClick = async () => {
    console.log("handleGoogleCalendarAuthClick");
    try {
      setLoading(true);
      if (!isGoogleCalendarSignInDone) {
        window.location.replace(
          `${APP_URL}/calender-authorization?token=${userData?.token}&redirctUrl=${window.location.href}`
        );
        return;
      }

      _userGoogleCalendarSync();
    } catch (error) {
      console.log("error >> ", error);
      errorHandler(error);
    }
  };

  // const _initializeGoogleCalendar = async () => {
  //   try {
  //     setLoading(true);
  //     await initializeClient();
  //     setLoading(false);
  //   } catch (error) {
  //     console.log("error >> ", error);
  //     errorHandler(error);
  //   }
  // };

  const _handleGoogleCalendarDisconnect = async () => {
    try {
      setLoading(true);
      await removeGoogleCalendarSync();
      dispatch(updateUserObj({ isGoogleCalendarSignInDone: false }));
      setLoading(false);
      fetchData();
      showToast("Google Calendar Disconnected Successfully", "success");
    } catch (error) {
      console.log("error >> ", error);
      errorHandler(error);
    }
  };

  const _toggleUpgradeAccountModal = () => {
    setIsUpgradeOpen((prev) => !prev);
  };

  // useEffect(() => {
  //   _initializeGoogleCalendar();
  // }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <>
      <Button
        color="primary"
        size="lg"
        disabled={loading}
        onClick={
          isRegularUser()
            ? _handleGoogleCalendarAuthClick
            : () => _toggleUpgradeAccountModal(true)
        }
      >
        {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}
        <img
          src={require("../../../assets/img/Google-calandar.png")}
          alt="google calendar"
          height={18}
        />{" "}
        Connect Google Calendar
      </Button>
      {isGoogleCalendarSignInDone ? (
        <>
          {/* <img
            src={require("../../../assets/img/d-sync.png")}
            alt="google calendar"
            height={35}
            onClick={_handleGoogleCalendarDisconnect}
          /> */}

          <div className="dSync_icon" onClick={_handleGoogleCalendarDisconnect}>
            <SvgIcons type={"dSync"} />
          </div>

          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}
        </>
      ) : null}

      {isUpgradeOpen ? (
        <UpgradeAccountModal
          isOpen={isUpgradeOpen}
          toggle={_toggleUpgradeAccountModal}
        />
      ) : null}
    </>
  );
};

export default GoogleCalendarSyncModal;
